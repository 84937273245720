import { useState, useEffect } from "react";
import { SelectedFile } from "./StartPipelineDialog";
import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { Elevation } from "@blueprintjs/core/lib/esm/common/elevation";
import { H4, H5 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Checkbox, Switch } from "@blueprintjs/core/lib/esm/components/forms/controls";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { getDateTimeString } from "../../utils/getDateTimeString";
import { useStartPipelineDialogStore } from "../../stores/StartPipelineDialogStore";
import { InputGroup } from "@blueprintjs/core/lib/esm/components/forms/inputGroup";
import { Toaster } from "../../utils/Toaster";
import { useOrganizationStore } from "../../stores/OrganizationStore";

export interface SelectedSample {
  name: string
  files: {
    fileName: string
    fileLink: string
  }[]
  project: string
  ncbiMetadata?: {
    NCBI_ACCESSION: string,
    SRR_ID: string
  }
}

export const FileSelectBaseSpace = () => {

  const { setSelectedFiles, setTotalSelectedSamples, totalSelectedSamples, basespaceProjectAsSequencerRunId, setBasespaceProjectAsSequencerRunId } = useStartPipelineDialogStore()

  const basespaceClientId = window.parsedConfig.BASESPACE_CLIENT_ID
  const basespaceRedirectURI = window.parsedConfig.BASESPACE_REDIRECT_URI

  const [selectedSamples, setSelectedSamples] = useState<SelectedSample[]>([])
  const [basespaceToken, setBasespaceToken] = useState<string>();
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [basespaceUser, setBaseSpaceUser] = useState<any>()
  const [originProjects, setOriginProjects] = useState<any>()
  const [basespaceProjects, setBaseSpaceProjects] = useState<any>()
  const [originSamples, setOriginSamples] = useState<any>()
  const [basespaceSamples, setBaseSpaceSamples] = useState<any>()
  const [basespaceProject, setBasespaceProject] = useState<any>()
  const [samplesLoading, setSamplesLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {organization} = useOrganizationStore()

  useEffect(() => {
    if (basespaceToken) {
      if (basespaceProject)
        getFiles(basespaceToken)
      else
        getProjects(basespaceToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basespaceToken])

  const getProjects = async (token: string) => {
    try {
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json'
      }
      const user = (await (await fetch("https://api.basespace.illumina.com/v1pre3/users/current/", {
        method: "GET",
        headers
      })).json()).Response
      setBaseSpaceUser(user)

      const projects = (await (await fetch(`https://api.basespace.illumina.com/v1pre3/users/${user.Id}/projects?Limit=1000&SortBy=DateModified&SortDir=Desc`, {
        method: "GET",
        headers
      })).json()).Response.Items

      setOriginProjects(projects)
      setBaseSpaceProjects(projects)
    } catch (error: any) {
      console.error(error)
      Toaster.show({ icon: "error", message: `An error occurred while fetching projects. Message from server: ${error}`, intent: "danger" })
    }
  }

  const getFiles = async (token: string) => {
    setSamplesLoading(true)
    try {
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json'
      }
      const samples = (await (await fetch(`https://api.basespace.illumina.com/v1pre3/projects/${basespaceProject.Id}/samples?Limit=1000&SortBy=DateCreated&SortDir=Desc`, {
        method: "GET",
        headers
      })).json()).Response

      const samplesWithFiles = await Promise.all(samples.Items.map(async (v: any) => ({
        ...v,
        files: (await (await fetch(`https://api.basespace.illumina.com/v1pre3/samples/${v.Id}/files`, {
          method: "GET",
          headers
        })).json()).Response.Items
      })))

      setOriginSamples(samplesWithFiles)
      setBaseSpaceSamples(samplesWithFiles)
    } catch (error: any) {
      console.error(error)
      Toaster.show({ icon: "error", message: `An error occurred while fetching files. Message from server: ${error}`, intent: "danger" })
    }

    setSamplesLoading(false)
  }

  const filterItemsByName = (items: any[], query: string) => {
    const matchingItems: any[] = [];
    if(!basespaceProject){
      for (const project of items) {
        // Check with case insensitivity
        if (project.Name.toLowerCase().includes(query.toLowerCase())) {
          matchingItems.push(project);
        }
      }
    } else {
      for (const sample of items) {
        // Check with case insensitivity
        if (sample.Name.toLowerCase().includes(query.toLowerCase())) {
          matchingItems.push(sample);
        }
      }
    }
    return matchingItems;
  }

  const basespaceLogin = (scope: string) => {
    const popup = window.open(`https://basespace.illumina.com/oauth/authorize?response_type=code&client_id=${basespaceClientId}&scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(basespaceRedirectURI)}`, "pop-up", "popup=true,width=900,height=800,left=500,top=100")
    const checkPopup = setInterval(async () => {
      if (popup?.window?.location.href.includes("basespace-auth")) {
        clearInterval(checkPopup);
        popup.close()
        const urlParams = new URLSearchParams((new URL(popup?.window.location.href)).search);
        const authCode = urlParams.get('code');
        try {
          const tokenResponse = await fetch(`${window.parsedConfig.API_DOMAIN}/api/auth/basespace_token`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              client_id: basespaceClientId,
              grant_type: "authorization_code",
              code: authCode,
              redirect_uri: basespaceRedirectURI
            })
          })
          if (tokenResponse.status !== 200)
            throw (new Error(`Failed HTTP Request - ${tokenResponse.url}: ${tokenResponse.status}`))
          const jsonTokenReponse = await tokenResponse.json()
          setBasespaceToken(jsonTokenReponse.access_token)
        }
        catch (e: any) {
          alert(`Error when logging into BaseSpace: ${e.message}`)
        }
      }
      if (!popup || !popup.closed)
        return;
      clearInterval(checkPopup);
    }, 1000)
  }

  const convertSelectSamplesToFiles = (selectedSamples: SelectedSample[]) => {
    const selectedFiles = selectedSamples.reduce((pv, cv) => {
      const files = cv.files.map(file => ({
        name: file.fileName,
        source: "basespace",
        fileType: "paired",
        remoteFileUrl: file.fileLink + `?access_token=${basespaceToken}&redirect=meta`,
        sequencerrun_id: basespaceProjectAsSequencerRunId ? cv.project : undefined
      })) as SelectedFile[]
      return [
        ...pv,
        ...files
      ]
    }, [] as SelectedFile[])
    setSelectedFiles(selectedFiles)
  }

  const addSamplesToSelectedFiles = () => {
    setSelectedSamples([])
    const newTotalSelectedSamples = [...totalSelectedSamples.filter(v => !selectedSamples.map(v => v.name).includes(v.name)), ...selectedSamples]
    setTotalSelectedSamples(newTotalSelectedSamples)
    convertSelectSamplesToFiles(newTotalSelectedSamples)
  }

  const selectSample = (sample: any) => {
    const sampleSelected = selectedSamples.filter(selectedSample => selectedSample.name === sample.Name)[0]
    setSelectedSamples([
      ...(sampleSelected ? selectedSamples.filter(selectedSample => selectedSample.name !== sample.Name) : [...selectedSamples, {
        name: sample.Name,
        files: sample.files.map((file: any, i: number) => ({
          fileName: file.Name,          
          fileLink: "https://api.basespace.illumina.com/" + file.HrefContent
        })),        
        project: basespaceProject.Name
      }])
    ])
  }

  const backToProjects_onCLick = () => {
    setBasespaceProject(undefined)
    setBaseSpaceSamples([])
    setSearchQuery("")
  }

  const removeSelectedSample_onClick = (sample: SelectedSample) => {
    const newTotalSelectedSamples = [...totalSelectedSamples.filter(v => v !== sample)]
    setTotalSelectedSamples(newTotalSelectedSamples)
    convertSelectSamplesToFiles(newTotalSelectedSamples)
  }

  return <>
    <div style={{ display: "flex", gap: 10 }}>
      <div style={{ flex: 1 }}>
        {basespaceToken && !basespaceProject
          ? <div style={{ display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
            <H4>My Projects</H4>
            <InputGroup
                placeholder="Search projects..."
                value={searchQuery}
                onChange={(e) => {
                  const filteredProjects = filterItemsByName(originProjects, e.target.value)
                  setSearchQuery(e.target.value);
                  setBaseSpaceProjects(filteredProjects)
                }}
                leftIcon="search"
            />
            {basespaceProjects ? basespaceProjects.length ? basespaceProjects.map((v: any, i: number) =>
              <Card
                key={i}
                elevation={Elevation.ONE}
                style={{ padding: 10 }}
                interactive
                onClick={() => {
                  setSearchQuery("")
                  setBasespaceProject(v)
                  basespaceLogin(`browse global, read project ${v.Id}`)
                  setSamplesLoading(true)
                }}
              >

                <div><Icon icon="projects"></Icon> {v.Name}</div>
                <div>Last Modified {getDateTimeString(v.DateModified)}</div>
              </Card>) 
              : 
              <div>No Projects Found</div> 
              : 
              <div>
                <Spinner></Spinner>
                <H5>Loading Projects...</H5>
              </div>
            }
          </div>
          : basespaceProject
            ? <div style={{ display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
              <div style={{ display: 'flex', gap: 5 }}>
                <Button onClick={backToProjects_onCLick} minimal>My Projects</Button>
                <H4><Icon icon="projects"></Icon> {basespaceProject.Name}</H4>
              </div>
              <InputGroup
                placeholder="Search samples..."
                value={searchQuery}
                onChange={(e) => {
                  const filteredSamples = filterItemsByName(originSamples, e.target.value)
                  setSearchQuery(e.target.value);
                  setBaseSpaceSamples(filteredSamples)
                }}
                leftIcon="search"
              />
              {!samplesLoading ? <div style={{ display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
                {basespaceSamples && basespaceSamples.length ? basespaceSamples.map((projectSample: any, i: number) =>
                  <Card
                    key={i}
                    elevation={Elevation.ONE}
                    style={{ padding: 10 }}
                  >
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        disabled={totalSelectedSamples.map(v => v.name).includes(projectSample.Name)}
                        checked={!!selectedSamples.filter(sample => sample.name === projectSample.Name)[0] || totalSelectedSamples.map(item => item.name).includes(projectSample.Name)}
                        onClick={() => selectSample(projectSample)} />
                      <H5><Icon icon="lab-test"></Icon> {projectSample.Name}</H5>
                    </div>
                    <div>Created: {getDateTimeString(projectSample.DateCreated)}</div>
                  </Card>)
                  :
                  <div>No Samples Found</div>
                }
              </div>
                : <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Spinner></Spinner>
                  <H5>Loading Samples...</H5>
                </div>}
              {selectedSamples.length ? <Button intent="primary" onClick={addSamplesToSelectedFiles}>Add Selected Samples to Pipeline</Button> : null}
            </div>
            : <Button intent="primary" icon="user" onClick={() => basespaceLogin("browse global")}>Login to BaseSpace</Button>

        }
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
        {!organization?.organizationName.toLowerCase().includes("calicinet") && 
          <div>
            <Switch label="Use project name as SequencerRun_Id" checked={basespaceProjectAsSequencerRunId} onChange={(e) => setBasespaceProjectAsSequencerRunId(e.currentTarget.checked)}></Switch>
          </div>
        }
        <H4>Selected Samples</H4>        
        {totalSelectedSamples.map((sample, i: number) =>
          <Card
            key={i}
            elevation={Elevation.ONE}
            style={{ padding: 10, display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <H5><Icon icon="lab-test"></Icon> {sample.name}</H5>
              <div>{sample.project}</div>
            </div>
            <Button icon="trash" minimal onClick={() => removeSelectedSample_onClick(sample)}></Button>
          </Card>)}
      </div>
    </div>

  </>

}