import * as yup from "yup";
import jsonLogic from 'json-logic-js';
import { FieldProps, Fields, Organism } from "../types/OrganismTypes";

export function GetSamplesNDBYupSchema(values: any, organism: Organism | undefined, importLevel?: string) {
  const organization = organism?.organization
  const organismFields: Fields = { required: ["Key"], properties: Object.fromEntries(Object.entries(organism?.properties!).filter(([k, v]) => (!v.level || v.level === "Specimen"))) }
  if (organization?.toLowerCase().includes('calicinet') && organism) {
    const fieldsEntries = Object.entries(organismFields.properties)
    const objSchemaEntries = fieldsEntries.map(([k, v]) => {
      let yupFieldSchema: any
      switch (v.type) {
        case "number": yupFieldSchema = yup.number(); break;
        case "numbernull": yupFieldSchema = yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null); break;
        case "date":
          if (k === "OnsetDate" && importLevel !== "results") {
            yupFieldSchema = yup.mixed().nullable(true).test(
              "onset-date-format",
              "OnsetDate must be a valid date in 'yyyy-mm-dd' format.",
              (value) => {
                if (value === null) return true;
                return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true;
              }
            );
          } else if (importLevel !== "results") {
            if (v.allowUnknown)
              yupFieldSchema = yup.mixed().nullable(true).transform((_, val) => {
                if (val === "" || val === null) return null;
                if (typeof val === 'string' && val.trim().toLowerCase() === "unknown") return val.trim().toLowerCase();
                return new Date(val);
              }).test(
                "allownUnknown-format",
                `${k} must be a valid date in 'yyyy-mm-dd' format, or 'Unknown'.`,
                (value) => {
                  if (typeof value === 'string' && value.trim().toLowerCase() === "unknown") return true;
                  if (value === null) return true;
                  return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true;
                }
              );
            else
              yupFieldSchema = yup.date().nullable(true).transform((_, v) => (v === "" || v === null) ? null : new Date(v));
          }
          break;
        default: yupFieldSchema = yup.string(); break;
      }

      if (organismFields.required.includes(k)) {
        // The field is required. If it's "OnsetDate" or "CollectionDate", it can be null.
        if (k === "OnsetDate" || k === "CollectionDate") {
          yupFieldSchema = yupFieldSchema.nullable(true)
        } else {
          yupFieldSchema = yupFieldSchema.required(`${k} is required.`)
        }
      }

      yupFieldSchema = importLevel === "results" ? CommonValidationsForResults(yupFieldSchema, values, k, v, organismFields) : CommonValidations(yupFieldSchema, values, k, v, organismFields, organism)

      return [k, yupFieldSchema]
    })
    
    const objSchema = Object.fromEntries(objSchemaEntries)

    return yup.object().shape(objSchema).required();
  }
}

function CommonValidations(yupFieldSchema: any, values: any, k: string, v: FieldProps, organismFields: Fields, organism: Organism) {
  if (v.max && yupFieldSchema.max) {
    const [max, message] = typeof (v.max) === "string" && v.max.startsWith("$")
      ? (v.max === "$today" && v.type === "date")
        ? [new Date(), `Future dates are not valid.`]
        : [yup.ref(v.max.substring(1)), `${k} cannot be after ${v.max.substring(1)}`]
      : [v.max, `The maximum value allowed for ${k} is ${v.max}.`]
    yupFieldSchema = yupFieldSchema.max(max, message).nullable(true)
  }

  if (v.min && yupFieldSchema.min) {
    const [min, message] = typeof (v.min) === "string" && v.min.startsWith("$")
      ? (v.min === "$today" && v.type === "date")
        ? [new Date(), `Past dates are not valid.`]
        : [yup.ref(v.min.substring(1)), `${k} cannot be before ${v.min.substring(1)}`]
      : [v.min, `The minimum value allowed for ${k} is ${v.min}.`]
    yupFieldSchema = yupFieldSchema.min(min, message).nullable(true)
  }

  if (v.invalidIf && !["OnsetDate", "CollectionDate", "ReceivedDate"].includes(k)) {
    if (v.invalidIf.message?.startsWith('standardization')) {
      const valuesWithEnum = {
        ...values,
        enum: v.enum
      }
      var before = valuesWithEnum[k]
      var matchedEnum;

      if (v.invalidIf.message.split("-")?.[1] === "standardizeEnums" && before) {
        matchedEnum = v.enum!.find(e => {
          const parts = e.toLowerCase().split('-');
          return parts.includes(before.toLowerCase()) || e.toLowerCase() === before.toLowerCase();
        });
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizePatientSex") {
        if (before && before !== 'Male' && (before.toLowerCase() === 'm' || before.toLowerCase() === 'male')) {
          matchedEnum = 'Male';
        } else if (before && before !== 'Female' && (before.toLowerCase() === 'f' || before.toLowerCase() === 'female')) {
          matchedEnum = 'Female';
        } else if (before && ['unknown', 'unavailable', "u"].includes(before?.toLowerCase() ?? '')) {
          matchedEnum = "Unavailable"
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "roundNumber") {
        if (!isNaN(Number(before)) && Number.isFinite(Number(before)) && Math.round(before) !== Number(before)) {
          matchedEnum = Math.round(before)
        } else if (before && before.toLowerCase() === 'pos') {
          matchedEnum = "Positive"
        } else if (before && before.toLowerCase() === 'neg') {
          matchedEnum = "Negative"
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "blankToUnknown") {
        matchedEnum = "Unknown"
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizeDashes") {
        if (before && before.toLowerCase().includes("sporadic -"))
          matchedEnum = before.replace("-", "–")
      }

      var after = matchedEnum ? matchedEnum : ""
      var beforeAfterMessage;
      if (after !== "") {
        beforeAfterMessage = `standardization before:${before} -> after:${after}`
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("standardization-error", beforeAfterMessage, () => !jsonLogic.apply(v.invalidIf!.rule, valuesWithEnum))
      } else {
        if (v.enum && !v.freeEntry)
          yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
            return value ? v.enum!.includes(value) : !organismFields.required.includes(k)
          })
      }
    } else
      yupFieldSchema = (yupFieldSchema as yup.NumberSchema).test("invalid-if", v.invalidIf.message, () => !jsonLogic.apply(v.invalidIf!.rule, values))
  }

  if (!v.allowUnknown && v.pattern)
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("pattern", `${k} must be in ${v.examples ? v.examples[0] : ""} format`, (value) => {
      return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true
    })

  if (v.enum && !v.freeEntry) {
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
      if (v.invalidIf && v.invalidIf.message?.startsWith('standardization')) {
        return true
      }

      // Non-required fields: allow null or if a value is present, it must be included in the enum
      if (!organism.required.includes(k)) {
        return value === undefined || value === "" || (typeof value === "string" && v.enum!.includes(value))
      }

      // Required fields: value must be a string and included in the enum
      return value
        ? v.enum!.includes(value)
        : !organismFields.required.includes(k);
    })
  }


  return yupFieldSchema
}

function CommonValidationsForResults(yupFieldSchema: any, values: any, k: string, v: FieldProps, organismFields: Fields) {
  if (["OnsetDate", "CollectionDate"].includes(k)) return null  
  if (v.invalidIf) {
    //data format standardization
    if (v.invalidIf.message?.startsWith('standardization')) {
      const valuesWithEnum = {
        ...values,
        enum: v.enum
      }
      var before = valuesWithEnum[k]
      var matchedEnum;

      if (v.invalidIf.message.split("-")?.[1] === "standardizeEnums" && before) {
        matchedEnum = v.enum!.find(e => {
          const parts = e.toLowerCase().split('-');
          return parts.includes(before.toLowerCase()) || e.toLowerCase() === before.toLowerCase();
        });
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizePatientSex") {
        if (before && before !== 'MALE' && (before.toLowerCase() === 'm' || before.toLowerCase() === 'male')) {
          matchedEnum = 'MALE';
        } else if (before && before !== 'FEMALE' && (before.toLowerCase() === 'f' || before.toLowerCase() === 'female')) {
          matchedEnum = 'FEMALE';
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "roundNumber") {
        if (!isNaN(Number(before)) && Number.isFinite(Number(before)) && Math.round(before) !== Number(before)) {
          matchedEnum = Math.round(before)
        } else if (before && before.toLowerCase() === 'pos') {
          matchedEnum = "Positive"
        } else if (before && before.toLowerCase() === 'neg') {
          matchedEnum = "Negative"
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "blankToUnknown") {
        matchedEnum = "Unknown"
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizeDashes") {
        if (before && before.toLowerCase().includes("sporadic -"))
          matchedEnum = before.replace("-", "–")
      }

      var after = matchedEnum !== undefined ? matchedEnum : ""
      var beforeAfterMessage;
      if (after !== "") {
        beforeAfterMessage = `standardization before:${before} -> after:${after}`
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("standardization-error", beforeAfterMessage, () => !jsonLogic.apply(v.invalidIf!.rule, valuesWithEnum))
      } else {
        if (v.enum && !v.freeEntry)
          yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
            return value ? v.enum!.includes(value) : !organismFields.required.includes(k)
          })
      }
    } else
      yupFieldSchema = (yupFieldSchema as yup.NumberSchema).test("invalid-if", v.invalidIf.message, () => !jsonLogic.apply(v.invalidIf!.rule, values))
  }

  if (v.enum && !v.freeEntry) {
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
      if (v.invalidIf && v.invalidIf.message?.startsWith('standardization')) {
        return true
      }
      // Required fields: value must be a string and included in the enum
      return value
        ? v.enum!.includes(value)
        : !organismFields.required.includes(k);
    })
  }
  return yupFieldSchema
}