import { useLabsStore } from "../stores/LabsStore"
import { useOrganismStore } from "../stores/OrganismStore"
import { PipelineTemplate } from "../types/LabTypes"
import { Sample } from "../types/SampleTypes"
import { Pipeline } from "../types/PipelineType"

var _ = require("lodash")

interface StartPipelineOnSelectedFunctions {
  getSampleMappings: (samples: Sample[], selectedTemplate: PipelineTemplate) => {
    [key: string]: any;
  }[]
  sampleMetadataTemplateIsValid: (sampleMetadataMapping: {
    [key: string]: any;
  }) => boolean
  allsampleMetadataTemplatesAreValid: (samples: Sample[], selectedTemplateName: string, pipeline: Pipeline | undefined) => boolean
  userSelectedNoneTemplate: (selectedTemplateName: string | undefined, requiresTemplate: boolean | undefined) => boolean
}

export const useStartPipelineOnSelectedFunctions = (): StartPipelineOnSelectedFunctions => {
  const { organism } = useOrganismStore()
  const { userLab } = useLabsStore();

  const getSampleMappings = (samples: Sample[], selectedTemplate: PipelineTemplate) => {
    return samples.map(sample => {
      return selectedTemplate?.fields.reduce((pv, field) => {
        if (field.condition) {
          if (field.condition.op === "eq" && sample.data.metadata[field.condition.field] !== field.condition.value)
            return pv
          if (field.condition.op === "ne" && sample.data.metadata[field.condition.field] === field.condition.value)
            return pv
        }
        if (field.fieldType === "mapping")
          pv[field.fieldName] = field.fieldValue.split(";").map(sampleFieldName => {
            const property = organism?.properties[sampleFieldName]
            if (!property?.path) {
              return ""
            }
            const value = _.get(sample, property.path)?.toString() as string ?? ""
            if (organism?.properties[sampleFieldName].type === "date") {
              if (isNaN(Date.parse(value)))
                return ""
              let date2 = new Date(value ? value + "T00:00:00" : "")
              if (field.dateFormat === "YYYY")
                return date2.getFullYear()
              else if(field.dateFormat === "YYYY-mm"){
                return `${date2.getFullYear()}-${(date2.getMonth() + 1).toString().padStart(2, "0")}`
              }
              else
                return `${date2.getFullYear()}-${(date2.getMonth() + 1).toString().padStart(2, "0")}-${date2.getDate().toString().padStart(2, "0")}`
            } else if (sample.organization.toLowerCase().includes('calicinet') && sampleFieldName === "dedup_setting" && value === "") {
                return "FALSE"
            } else if (sample.organization.toLowerCase().includes('calicinet') && sampleFieldName === "target_depth" && value === "") {
              return "300"
          } else if (sample.organization.toLowerCase().includes('calicinet') && sampleFieldName.startsWith("Conventional-RT-PCR_RegC-")) {
            const reg_c = _.get(sample, "data.metadata.Conventional-RT-PCR_RegC-GI")?.toString() === "Positive" ||  _.get(sample, "data.metadata.Conventional-RT-PCR_RegC-GII")?.toString() === "Positive"
            return reg_c
          }
            return value ?? ""
          }).join(" ").trim();
        if (field.fieldType === "value")
          pv[field.fieldName] = field.fieldValue
        return pv
      }, {} as { [key: string]: any }) ?? {}
    })
  }

  const sampleMetadataTemplateIsValid = (sampleMetadataMapping: { [key: string]: any; }) => {
    return Object.entries(sampleMetadataMapping).filter(([, v]) => !v).length === 0
  }

  const allsampleMetadataTemplatesAreValid = (samples: Sample[], selectedTemplateName: string, pipeline: Pipeline | undefined) => { 
    const template = userLab?.pipelineTemplates.concat(pipeline?.defaultPipelineTemplates ?? []).find(v => v.name === selectedTemplateName)   
    if (template)
      return getSampleMappings(samples, template)
        .map(sampleMetadataMapping => sampleMetadataTemplateIsValid(sampleMetadataMapping)) //array of 'are all object values truthy'
        .filter(allTruthy => !allTruthy).length === 0 //no samples are missing a mapping value
    else
      return true
  }

  const userSelectedNoneTemplate = (
    selectedTemplateName: string | undefined, 
    requiresTemplate: boolean = false): boolean => {
    return (requiresTemplate && (!selectedTemplateName || selectedTemplateName === ""))
  }

  return { getSampleMappings, sampleMetadataTemplateIsValid, allsampleMetadataTemplatesAreValid, userSelectedNoneTemplate }
}