import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Dialog } from "@blueprintjs/core/lib/esm/components/dialog/dialog";
import { Card, ProgressBar, Switch } from "@blueprintjs/core/lib/esm/components";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import "../styles/FormInput.css";
import { useAppStore } from '../stores/AppStore';
import { Sample, SampleMetadata } from '../types/SampleTypes';
import { Outbreak } from "../types/OutbreakTypes";
import { SampleFileUpload } from './SampleFileUpload';
import { SampleAPI, OutbreakAPI } from '../api/DataAPI';
import { useAuth } from "react-oidc-context";
import { GetSamplesYupSchema } from '../utils/SampleYupSchema';
import { GetSamplesNDBYupSchema } from "../utils/SampleNDBYupSchema";
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import * as yup from "yup";
import { useOrganismStore } from "../stores/OrganismStore";
import { Log } from "./ConfirmationModal";
import { Toaster } from "../utils/Toaster";
import { useDataViewerCtrFunctions } from "../hooks/useDataViewerCtrFunctions";
import { useDataViewerCtrStore } from "../stores/DataViewerCtrStore";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { useUserStore } from "../stores/UserStore";
import { GetOutbreaksYupSchema } from "../utils/OutbreakYupSchema";


interface ImportMetadataProps {
  resetValues?: boolean
}

interface UpdateStatus {
  id: string,
  dbId?: string
  errorMessage?: string
}

export function ImportMetadata({ resetValues }: ImportMetadataProps) {
  const { loadView } = useDataViewerCtrFunctions()
  const { setSelectedSampleIds } = useDataViewerCtrStore()
  const [loading, setLoading] = useState(false);
  const { selectedViewId, selectedView } = useAppStore();
  const [ndbUpdate, setNbdUpdate] = useState(selectedView?.nationalDatabase)
  const [insertStatus, setInsertStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus[]>([])
  const [failedCount, setFailedCount] = useState(0);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const { organism } = useOrganismStore();
  const { organization } = useOrganizationStore()
  const { user } = useUserStore()
  const auth = useAuth();
  const [sampleExistsIndex, setSampleExistsIndex] = useState<boolean[]>([])
  const [sampleExistsNDBIndex, setSampleExistsNDBIndex] = useState<boolean[]>([])
  const [existingSamples, setExistingSamples] = useState<Sample[]>([])
  const [existingOutbreaks, setExistingOutbreaks] = useState<Outbreak[]>([])
  const [showImportCompleted, setShowImportCompleted] = useState(false);
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")

  const sampleAPI = new SampleAPI(auth.user?.access_token ?? "")
  const outbreakAPI = new OutbreakAPI(auth.user?.access_token ?? "")

  const form = useForm({
    mode: "onBlur"
  });
  const { handleSubmit } = form;

  useEffect(() => {
    if (resetValues) {
      setData([]);
      setInsertStatus("");
      setShowImportCompleted(false)
    }
  }, [resetValues])

  // get csv data from child component 'SampleFileUpload'
  const [data, setData] = useState<SampleMetadata[]>([]);
  const [allData, setAllData] = useState<SampleMetadata[]>([]);
  const [importLevel, setImportLevel] = useState("")
  const [altIdentifier, setAltIdentifier] = useState(organization?.identifierMetadataField?.trim() || "Key")

  useEffect(() => {
    setUpdateStatus([])
    setInsertStatus("")
    setShowImportCompleted(false)
  }, [data])

  // define function to pass to child component
  const childToParent = async (childdata: SampleMetadata[]) => {
    setAllData(childdata)
    const findIfSampleExists = async (identifier: string) => {
      try {
        let sample;
        if (ndbUpdate) {
          sample = await sampleAPI.getNdbByKey(identifier)
        } else if (organization?.useLabAccession) {
          sample = await sampleAPI.getByLabAccessionNumber(identifier)
        } else if (altIdentifier === "Key") {
          sample = await sampleAPI.getByKey(identifier)
        } else {
          try {
            let searchResponse = await sampleAPI.search(
              {
                QueryString: `{\"data.metadata.${altIdentifier}\":\"${identifier}\"}`,
                isCaseSensitive: true,
                organism: organism?.name ?? "",
                organization: organization?.organizationName ?? ""
              },
              0,
              1
            );
            sample = searchResponse?.items[0];
          } catch (error) {
            console.error("Error during sample search:", error);
            sample = null;
          }
        }
        return !!sample
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(error)
        return false
      }
    }
    const findIfSampleExistsNDB = async (identifier: string) => {
      try {
        if (altIdentifier === "Key") {
          const sample = await sampleAPI.getNdbByKey(identifier)
          return sample
        } else {
          const ndbQuery = {
            "organism": organism?.name,
            "criteria": {
                "criteria": [
                    {
                        "field": altIdentifier,
                        "value": identifier,
                        "operator": "equals",
                        "valueSource": "value"
                    }
                ],
                "operator": "and"
            }
        }  
        const response = await sampleAPI.query_ndb(JSON.stringify(ndbQuery), undefined, undefined, undefined, undefined, false)
        const sample = response.items[0]
        return sample
        }

      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(error)
        return undefined
      }
    }

    const findIfOutbreakExists = async (identifier: string) => {
      if (!identifier) return false
      try {
        await outbreakAPI.getByKey(identifier)
        return true
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(error)
        return false
      }
    }

    const findIfOutbreakExistsNDB = async (identifier: string) => {
      try {
        const outbreak = await outbreakAPI.getNdbByKey(identifier)
        return outbreak
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.error(error)
        return undefined
      }
    }

    const errorRecords: Record<number, Array<{ field: string, message: string }>> = {};
    let failureCount = 0;
    // for display
    let standardizedData = [...childdata];
    // for submission
    let validatedData = [...childdata];
    let changeLog: Log[] = [];
    // to store unique data to changeLog
    let fieldsAdded = new Set();
    // Check if the last element is an empty
    if (organism?.organization.includes('PulseNet') && childdata[childdata.length - 1]?.Key === '') {
      // Remove the last element from childdata
      childdata.pop();
    }
    if (!organism)
      throw (new Error("Organism is not defined."))

    const nulledchilddata = childdata.map((v) => Object.fromEntries(Object.entries(v).map((v2) => {
      const field = organism.properties[v2[0]]
      if (v2[1] === '')
        return [v2[0], undefined]
      if (field && v2[1] && typeof (v2[1]) === "string" && ["number", "numbernull"].includes(field.type) && !isNaN(parseInt(v2[1])))
        return [v2[0], parseInt(v2[1])]
      return v2
    })))
    let promises = [] as Promise<boolean>[]
    let ndbPromises = [] as Promise<Sample | Outbreak |  null | undefined>[]
    let sampleExistsIndex = [] as boolean[]
    let sampleExistsNDBIndex = [] as boolean[]

    for (let i = 0; i < childdata.length; i++) {
      if (ndbUpdate) {
        const ndbSampleLookupKey = `${ndbUpdate ? "" : user!.labId.padEnd(5, "_")}${(altIdentifier!=="Key" ? (nulledchilddata[i][altIdentifier]?.toString() || "" ) : (nulledchilddata[i].Key?.toString() || "" ))}`
        const ndbSampleExists = importLevel === "outbreak" ? findIfOutbreakExistsNDB(nulledchilddata[i].LabOBNumber?.toString() || "") : findIfSampleExistsNDB(ndbSampleLookupKey)
        ndbPromises.push(ndbSampleExists)
        if (promises.length >= 20 || childdata.length - 1 === i) {
          const samples = await Promise.all(ndbPromises)
          sampleExistsNDBIndex = [...sampleExistsNDBIndex, ...(samples.map(v => !!v))]
          setExistingSamples(existingSamples => [...existingSamples,  ...(samples.filter(v => v) as Sample[])])
          setExistingOutbreaks(existingOutbreaks => [...existingOutbreaks,  ...(samples.filter(v => v) as Outbreak[])])
          ndbPromises = [];
        }
      } else {
        const sampleLookupKey = organization?.useLabAccession ? (nulledchilddata[i].LabAccessionNumber?.toString() || "") : 
        (altIdentifier ? (nulledchilddata[i][altIdentifier]?.toString() || "" ) : (nulledchilddata[i].Key?.toString() || "" ))
        const sampleExists = importLevel === "outbreak" ? findIfOutbreakExists(nulledchilddata[i].LabOBNumber?.toString() || "") : findIfSampleExists(sampleLookupKey)

        promises.push(sampleExists)
        if (promises.length >= 20 || childdata.length - 1 === i) {
          sampleExistsIndex = [...sampleExistsIndex, ...(await Promise.all(promises))]
          promises = [];
        }
      }
      
    }
    setSampleExistsIndex(sampleExistsIndex)
    setSampleExistsNDBIndex(sampleExistsNDBIndex)

    childdata.forEach((v, i) => {
      const sampleExists = sampleExistsIndex[i];
      const sampleExistsNDB = sampleExistsNDBIndex[i];
      let standardizationErrorOccurred = false;
      try {
        const yupSchema = yup.lazy(values => importLevel === "outbreak" ? GetOutbreaksYupSchema(values, organism, ndbUpdate, (v["NORSNumber"] !== undefined && v["NORSNumber"] !== "")) : 
                                  (ndbUpdate && organization?.organizationName.toLowerCase().includes('calicinet')? GetSamplesNDBYupSchema(values, organism, importLevel) : GetSamplesYupSchema(values, organism, user?.labId!, importLevel, altIdentifier)));
        yupSchema.validateSync(nulledchilddata[i], { abortEarly: false });
        if (!organization?.organizationName.toLowerCase().includes('calicinet') && sampleExistsNDB && !ndbUpdate)
          failureCount++;
      } catch (e: any) {
        if (!e.inner)
          throw (e);
        const errors = e.inner.map((v: any) => ({ field: v.path, message: v.message })) as any[]
        errors.forEach((error: any) => {
          const fieldName = error.field
          const requiredError = ndbUpdate ? null : error.message.includes("required")
          const fieldsInData = Object.keys(e.value)
          //non Human SourceType does not need to check PatientSex, task 23249
          if (organism?.organization.includes('PulseNet') && childdata[i]["SourceType"] !== "Human" && fieldName === "PatientSex") { }
          else {
            if (fieldName) {
              //For standardization case, avoid adding it to errorRecords
              if (error.message?.startsWith("standardization")) {
                const regex = /before:(.*?) -> after:(.*)/
                const matches = error.message.match(regex)

                if (matches) {
                  const beforeValue = matches[1]
                  const afterValue = matches[2]
                  if ((sampleExists || sampleExistsNDB) && beforeValue == "undefined") {
                    // For existing samples, do not transform values if input value is undefined
                    return 
                  }
                  if (!fieldsAdded.has(fieldName + ':' + beforeValue)) {
                    changeLog.push({
                      field: fieldName,
                      before: beforeValue,
                      after: afterValue
                    })
                    fieldsAdded.add(fieldName + ':' + beforeValue)
                  }
                  standardizedData[i][fieldName] = afterValue
                  if (validatedData && validatedData[i])
                    validatedData[i][fieldName] = afterValue
                }
                standardizationErrorOccurred = true
              }
              else {
                if (sampleExists || sampleExistsNDB) {
                  if (requiredError || !fieldsInData.includes(error.field)) {
                    return
                  }
                }
                if (!errorRecords[i]) {
                  errorRecords[i] = [];
                }
                errorRecords[i].push({
                  field: fieldName,
                  message: error.message
                });
                if (validatedData && errorRecords[i].length === 1)
                  delete validatedData[i];
              }
            }
          }
        });
        if (!standardizationErrorOccurred && !sampleExists && !sampleExistsNDB)
          failureCount++;
      }

    })
    setFailedCount(failureCount);

    validatedData = validatedData.filter(entry => entry !== undefined);
    if (organization?.organizationName.toLowerCase().includes('calicinet'))
      validatedData = validatedData.map(item => 
        Object.fromEntries(
            Object.entries(item).map(([key, value]) => [key, value === "" ? undefined : value])
        ) as SampleMetadata)
  
    return {
      parsedErrors: errorRecords,
      validatedData,
      standardizedData,
      changeLog
    };
  }


  const formatDatesToString = (data: any): any => {
    let entries = Object.entries(data)
    entries = entries.map(([k, v]: [string, any]) => [k, v instanceof Date ? `${v.getUTCFullYear()}-${('0' + (v.getUTCMonth() + 1)).slice(-2)}-${('0' + v.getUTCDate()).slice(-2)}` : v])

    //Replace blank string values with null to avoid errors
    entries = entries.map(([k, v]: [string, any]) => [k, v === "" ? null : v])
    return Object.fromEntries(entries)
  }
  const onFailedsamplecheck = async () => {
    if (failedCount > 0) {
      setShowWarningDialog(true);
      return;
    }
    onSubmit();
  };
  const onWarningDialogProceed = async () => {
    setShowWarningDialog(false);
    onSubmit();
  };

  // const importableSampleCount = () => existingSamples.filter(v => (ndbUpdate && v) || true).length

  const generateOutbreakData = (filteredBulkData: any) => {
    var outbreaks = []
    for (var r = 0; r < filteredBulkData.length; r++) {
      //case - insensitive duplicate check for 'key' of the sample
      //if exists, update the corresponding sample 
      let key: string = filteredBulkData[r].LabOBNumber as string || ""
      var outbreak = {
        identifier: key,
        organism: organism?.name,
        organization: organism?.organization,
        data: {
          metadata: formatDatesToString(filteredBulkData[r]),
        },
      } as Outbreak

      outbreaks.push(outbreak);
    }
    return outbreaks
  }  

  const updateNDBSample = async (sample: Sample) => {
    try {
      // debugger
      var existingSample = existingSamples.find(v => v.identifier === sample.identifier)
      var sampleRes = existingSample ?? await sampleAPI.getNdbByKey(sample.identifier)
      if (sampleRes) {
        await sampleAPI.updateNDBMetadata(sampleRes?.id, sample.data.metadata)
      }
      if (!sampleRes)
        throw new Error("sample key not found")
      return { id: sample.identifier, dbId: sampleRes.id } as UpdateStatus
      // setUpdateStatus(prev => [...prev, { id: sample.identifier }])
    } catch (error) {
      return { id: sample.id, errorMessage: `${error}` } as UpdateStatus
      // setUpdateStatus(prev => [...prev, { id: sample.identifier, errorMessage: `${error}` }])
    }
  }

  const updateNDBOutbreak = async (outbreak: Outbreak) => {
    try {
      var outbreakRes = await outbreakAPI.getNdbByKey(outbreak.identifier!)
      if (outbreakRes) {
        await outbreakAPI.updateNDBMetadata(outbreakRes?.id, outbreak.data.metadata)
      }
      if (!outbreakRes)
        throw new Error("outbreak LabOBNumber not found")
      return { id: outbreak.identifier, dbId: outbreakRes.id } as UpdateStatus
    } catch (error) {
      return { id: outbreak.id, errorMessage: `${error}` } as UpdateStatus
    }
  }


  const onSubmit = async () => {
    setLoading(true)
    var message = "";
    var isError = false;
    const fieldProp = organism?.properties
    var bulkData = [];
    const sampleExistsLookup = data.map((v, i) => ({
      "identifier": !organization?.organizationName.toLowerCase().includes('calicinet')
        ? (altIdentifier === "Key") ? v.Key : v[altIdentifier]
        : importLevel === "outbreak"
          ? v.LabOBNumber
          : (ndbUpdate ? v.Key : v.LabAccessionNumber),
      "existsLabDB": sampleExistsIndex[i],
      "existsNDB": sampleExistsNDBIndex[i]
    }))

    for (var row = 0; row < data.length; row++) {
      var filteredRow = Object.fromEntries(
        Object.entries(data[row])
          .filter(([key]) => fieldProp?.hasOwnProperty(key))
      );
      bulkData.push(filteredRow);
    }

    var filteredBulkData = bulkData.filter(function (row) {
      return organization?.generateKey || (
          (altIdentifier !== "Key" ? true : (row.Key !== "" && row.ReceivedDate !== "")) &&
          (!sampleExistsLookup.find(v => v.identifier === row[altIdentifier])?.existsNDB || ndbUpdate)
      );
  });

    if (importLevel === "outbreak") {
      var outbreaks = await generateOutbreakData(filteredBulkData)
      let reloadTable = false
      if (ndbUpdate) {
        let count = 0;
        let promises = [] as {func: (outbreak: Outbreak) => Promise<UpdateStatus>, arg: Outbreak}[]
        let outbreakIdList = [] as string[]
        let local_updateStatus = [] as UpdateStatus[]
        for (var i = 0; i < outbreaks.length; i++) {
          const outbreak = outbreaks[i];
          
          promises.push({func: updateNDBOutbreak, arg:outbreak})
          if (count < 9 && i !== outbreaks.length-1) {
            count++
          }
          else {
            count = 0
            const updates = await Promise.all(promises.map(v => v.func(v.arg)));
            local_updateStatus = [...local_updateStatus, ...updates]
            setUpdateStatus(prev => [...prev, ...updates])
            outbreakIdList = [...outbreakIdList, ...updates.filter(v => v.dbId).map(v => v.dbId!)]
            promises = []
          }
          reloadTable = true
        }        
        
        const message = `Successfully updated ${local_updateStatus.filter(v => !v.errorMessage).length} NDB outbreaks.`
        showToaster(message, false)
        setInsertStatus( message)
        
        setSelectedSampleIds(outbreakIdList)
        if (selectedViewId && reloadTable) {
          await loadView(selectedViewId)
        }
      } else {
        var returnValue1 = await outbreakAPI.bulkInsert(outbreaks)
        const outbreakIdArray = [...returnValue1.insertedOutbreaks, ...returnValue1.updatedOutbreaks].map(outbreak => outbreak.id)
        setSelectedSampleIds(outbreakIdArray)
        setData([]);
        if (returnValue1.failedOutbreaks.length) {
          message = `Outbreak Import Completed.\n Outbreaks Inserted: ${returnValue1.insertedOutbreaks.length}. \n Outbreaks Updated: ${returnValue1.updatedOutbreaks.length}`;
          message += returnValue1.failedOutbreaks.length ? `\n Outbreaks Errored: ${returnValue1.failedOutbreaks.length} \n` : ""
          message += returnValue1.failedOutbreaks.join("\n")
          setInsertStatus("Error");
          isError = true;
          showToaster(message, isError)
        } else {
          message = `Outbreak Import Completed.\n Outbreaks Inserted: ${returnValue1.insertedOutbreaks.length}. \n Outbreaks Updated: ${returnValue1.updatedOutbreaks.length}`;
          message += returnValue1.failedOutbreaks.length ? `\n Outbreaks Errored: ${returnValue1.failedOutbreaks.length}` : ""
          setInsertStatus("message");
          showToaster(message, isError)
        }
      }
      
    } else {
      var samples = [];
      for (var r = 0; r < filteredBulkData.length; r++) {
        //case - insensitive duplicate check for 'key' of the sample
        //if exists, update the corresponding sample 
        let key: string = (!ndbUpdate && organization?.useLabAccession) ? filteredBulkData[r].LabAccessionNumber as string : filteredBulkData[r][altIdentifier] as string;
        var outbreak = (organization?.organizationName.toLowerCase().includes('calicinet') && filteredBulkData[r].LabOBNumber) ? await outbreakAPI.getByKey(filteredBulkData[r].LabOBNumber as string) : null
        var outbreakId = outbreak ? outbreak.id : null
        try {
          var existingSample = organization?.useLabAccession ? await sampleAPI.getByLabAccessionNumber(key) : null
          outbreakId = existingSample?.data.outbreak ?? outbreakId
        } catch (error) {
          // eslint-disable-next-line no-console
        }
        if (altIdentifier !== "Key") {
          // when altIdentifier is not Key, the sample should be an existing sample
          try {
            if (ndbUpdate) {
              const ndbQuery = {
                "organism": organism?.name,
                "criteria": {
                    "criteria": [
                        {
                            "field": altIdentifier,
                            "value": key,
                            "operator": "equals",
                            "valueSource": "value"
                        }
                    ],
                    "operator": "and"
                }
            }  
            const response = await sampleAPI.query_ndb(JSON.stringify(ndbQuery), undefined, undefined, undefined, undefined, false)
            const existingNDBSample = response.items[0]
            key = existingNDBSample.identifier
            } else {
              let searchResponse = await sampleAPI.search(
                {
                  QueryString: `{\"data.metadata.${altIdentifier}\":\"${filteredBulkData[r][altIdentifier]}\"}`,
                  isCaseSensitive: true,
                  organism: organism?.name ?? "",
                  organization: organization?.organizationName ?? ""
                },
                0,
                1
              );
              existingSample = searchResponse?.items[0];
              key = existingSample.identifier
            }
            
          } catch (error) {
              continue 
          }
        }
        var sample = {
          identifier: key, // labAccession for CaliciNet Lab Import
          organism: organism?.name,
          organization: organism?.organization,
          data: {
            metadata: formatDatesToString(filteredBulkData[r]),
            outbreak: outbreakId
          },
        } as Sample
        
        if (organization?.organizationName.toLowerCase().includes('calicinet')) {
          sample.data.metadata["Specimen_Classification"] = outbreakId  == null ? "Sporadic" : "Outbreak"
        }
        samples.push(sample);
      }

      let reloadTable = false
      if (ndbUpdate) {
        let count = 0;
        let promises = [] as {func: (sample: Sample) => Promise<UpdateStatus>, arg: Sample}[]
        let sampleIdList = [] as string[]
        let local_updateStatus = [] as UpdateStatus[]
        for (var i = 0; i < samples.length; i++) {
          const sample = samples[i];
          
          promises.push({func: updateNDBSample, arg:sample})
          if (count < 9 && i !== samples.length-1) {
            count++
          }
          else {
            count = 0
            const updates = await Promise.all(promises.map(v => v.func(v.arg)));
            local_updateStatus = [...local_updateStatus, ...updates]
            setUpdateStatus(prev => [...prev, ...updates])
            sampleIdList = [...sampleIdList, ...updates.filter(v => v.dbId).map(v => v.dbId!)]
            promises = []
          }
          reloadTable = true
        }        
        
        const message = `Successfully updated ${local_updateStatus.filter(v => !v.errorMessage).length} samples.`
        showToaster(message, false)
        setInsertStatus( message)
        
        setSelectedSampleIds(sampleIdList)
        if (selectedViewId && reloadTable) {
          await loadView(selectedViewId)
        }
      } else {
        try {
          var returnValue = await sampleAPI.bulkInsert(samples)
          const sampleIdArray = [...returnValue.insertedSamples, ...returnValue.updatedSamples].map(sample => sample.id)
          setSelectedSampleIds(sampleIdArray)
          setData([]);
          if (returnValue) {
            message = `Sample Import Completed.\n Samples Inserted: ${returnValue.insertedSamples.length}. \n Samples Updated: ${returnValue.updatedSamples.length}`;
            message += returnValue.erroredSamples.length ? `\n Samples Errored: ${returnValue.erroredSamples.length}` : ""
            setInsertStatus(message);
          } else {
            setInsertStatus("Error");
            isError = true;
            message = `Bulk insert error: Please check the data and try again.`;
          }

          // insert bulk
          // setSamples(await sampleAPI.getAll())
          showToaster("Sample Import Completed", isError)
          //setShowImportMetadataDialog(false)
          if (selectedViewId)
            await loadView(selectedViewId)
        } catch (e) {
          if (message !== "")
            showToaster(message, isError)
        }
      }
    }
    setShowImportCompleted(true)
    setLoading(false)
  };

  const showToaster = (message: string, isError: boolean) => {
    if (!isError)
      Toaster.show({ icon: "tick", message: message, intent: "success" })
    else
      Toaster.show({ icon: "error", message: message, intent: "danger" })
  };

  return <>

    <div>
      <SampleFileUpload
        childToParentData={childToParent}
        setData={setData}
        setImportLevel={setImportLevel}
        ndb={ndbUpdate}
        sampleExistsIndex={sampleExistsIndex}
        sampleExistsNDBIndex={sampleExistsNDBIndex}
        setAltIdentifier={setAltIdentifier}
        altIdentifier={altIdentifier}
      />
    </div>
    {
      user?.role.includes("DatabaseManager") && (
        <Switch
          checked={selectedView?.nationalDatabase ? true : undefined}
          onClick={() => setNbdUpdate(!ndbUpdate)}
          style={{ marginLeft: 5, marginTop: 5 }}
        >
          Import to National Database
        </Switch>
      )
    }
    <span> &nbsp;&nbsp;</span>
    <div>
      <form onSubmit={handleSubmit(async () => await onFailedsamplecheck())} className="pn-form">
        <div style={{ display: "flex", padding: 10 }}>
          {insertStatus && showImportCompleted && <Card>
            {insertStatus === "Error" ?
              <span className="bp4-intent-danger"><Icon icon="error" intent="danger"></Icon> Import Failed. Please check file for any invalid data. </span>
              : insertStatus ?
                <span className="bp4-intent-success"><Icon icon="tick" intent="success"></Icon> {insertStatus} </span> : null}
          </Card>}
          {!showImportCompleted && <Button type='submit'
            className={`bp4-intent-primary bp4-icon-import`}
            // style={{ display: data.length > 0 ? "inline-block" : "none" }}
            disabled={loading || failedCount >= data.length}
            loading={loading}
          >Import {importLevel === "outbreak" ? `Outbreaks` : `Samples`}</Button>}
          {loading && <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ padding: 3 }}>Importing {importLevel === "outbreak" ? `Outbreaks` : `Samples`}...</div>
          </div>}
          {!!updateStatus.length && loading && <div style={{ width: "80%", display: "flex", gap: 5 }}>
            {/* Samples Updated: {updateStatus.length}/{sampleExistsNDBIndex.filter(v => v).length} */}
            <div>{((updateStatus.length / sampleExistsNDBIndex.filter(v => v).length) * 100).toFixed(0)}%</div>
            <ProgressBar value={updateStatus.length / sampleExistsNDBIndex.filter(v => v).length} intent="primary"></ProgressBar>            
          </div>
          }
        </div>
      </form>
    </div>
    <Dialog
      isOpen={showWarningDialog}
      title="Warning"
      icon="warning-sign"
      onClose={() => setShowWarningDialog(false)}
      style={{ width: "512px" }}
    >
      <div className="bp3-dialog-body" style={{ textAlign: "left", margin: "15px" }}>
        There are {allData.length - data.length + failedCount} samples that failed validation. These samples will not be imported. Do you want to proceed?
      </div>
      <div className="bp3-dialog-footer" style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginRight: "10px" }}>
          <Button intent="danger" onClick={() => setShowWarningDialog(false)}>Cancel</Button>
        </div>
        <div style={{ marginRight: "10px" }}>
          <Button intent="primary" onClick={onWarningDialogProceed}>Proceed</Button>
        </div>
      </div>
    </Dialog>
  </>

}