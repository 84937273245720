import { Pipeline, PipelineDTO, PipelineExecutionRequest } from "../types/PipelineType";
import { BulkLinkFileBody, FindClustersResult, Sample, SampleAnalysis, SampleBulkInsertResponse, SampleDTO, SampleMetadata, SampleProcesses, SearchResponse } from "../types/SampleTypes";
import { FavoriteComparison, FavoriteView, User, UserDTO } from "../types/UserTypes";
import { View, ViewDTO, ViewQuery } from "../types/ViewTypes";
import { PipelineRun, PipelineRunDTO } from "../types/PipelineRunTypes";
import { Weblog } from "../types/WeblogTypes";
import { ComparedSample, ComparedSampleAnalysis, Comparison, ComparisonDTO, ComparisonWithAnalysis, MegaCCRequest } from "../types/ComparisonTypes";
import { PipelineRunEvent, PipelineRunEventDTO } from "../types/EventTypes";
import { ProfileMatchingBody, ProfileMatchingResponse } from "../types/ProfileMatchingTypes";
import { Organism, OrganismDTO } from "../types/OrganismTypes";
import { Outbreak, OutbreakDTO, OutbreakBulkInsertResponse, OutbreakMetadata } from "../types/OutbreakTypes";
import { Organization, OrganizationDTO } from "../types/OrganizationTypes";
import { Lab, LabDTO, PipelineTemplate } from "../types/LabTypes";
import { useOrganizationStore } from "../stores/OrganizationStore";
import { ProcesstypeList } from "../stores/ComparisonViewStore";
import { ADXAnalysis } from "../types/ADXTypes";
import { editedTraces } from "../components/SampleDialog";

function currentOrg() {
  return useOrganizationStore.getState().organization?.organizationName || ""
}

export class DataAPI<T, DTO> {
  baseUrl: string;
  header: {
    [key: string]: string
  }

  constructor(entityName: string, token: string) {
    this.baseUrl = `${window.parsedConfig.API_DOMAIN}/api/` + entityName
    this.header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }

  async fetchJson(uri: string, method: string, body?: string, signal?: AbortSignal, additionalHeaders?: Record<string, string>): Promise<any> {
    try {
      const response = await fetch(uri, {
        method: method,
        headers: {
          ...this.header,
          ...additionalHeaders,
        },
        body,
        signal
      })
      const contentType = response.headers.get("content-type");
      if(contentType === "application/octet-stream"){
        return response
      }
      const result = (contentType && contentType.indexOf("application/json") !== -1) ? await response.json() : await response.text()
      if (response.ok)
        return result
      else
        throw Error(result)
    }
    catch (e) {
      // console.error(e)
      throw e
    }
  }

  async getAll(organization?: string ): Promise<T[]> {
    let params = new URLSearchParams();
    if (organization)
      params.set("organization", organization)
    if (currentOrg())
      params.set("organization", currentOrg())  
    return await this.fetchJson(`${this.baseUrl}?${params}`, 'GET')
  }

  async get(id: string): Promise<T> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${id}?${params}`, 'GET')
  }

  async insert(item: DTO) {
    return await this.fetchJson(`${this.baseUrl}`, 'POST', JSON.stringify(item)) as T
  }

  async update(id: string, item: DTO) {
    return await this.fetchJson(`${this.baseUrl}/${id}`, 'PUT', JSON.stringify(item)) as boolean
  }

  async delete(id: string) {
    return await this.fetchJson(`${this.baseUrl}/${id}`, 'DELETE')
  }
}

export class UserAPI extends DataAPI<User, UserDTO> {
  constructor(token: string) {
    super("users", token)
  }
  async getcurrentuser(): Promise<User> {
    return await this.fetchJson(`${this.baseUrl}/getcurrentuser`, 'GET')
  }
  async insertFavoriteView(favoriteView: FavoriteView, user: User): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteViews`, 'POST', JSON.stringify({ ...favoriteView }));
  }
  async updateFavoriteView(favoriteView: FavoriteView, user: User, viewId: string): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteViews/${viewId}`, 'PUT', JSON.stringify({ ...favoriteView }));
  }
  async deleteFavoriteView(user: User, viewId: string): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteViews/${viewId}`, 'DELETE');
  }
  async insertFavoriteComparison(favoriteComparison: FavoriteComparison, user: User): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteComparisons`, 'POST', JSON.stringify({ ...favoriteComparison }));
  }
  async updateFavoriteComparison(favoriteComparison: FavoriteComparison, user: User, viewId: string): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteComparisons/${viewId}`, 'PUT', JSON.stringify({ ...favoriteComparison }));
  }
  async deleteFavoriteComparison(user: User, viewId: string): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${user.id}/FavoriteComparisons/${viewId}`, 'DELETE');
  }
  
}

export class SampleAPI extends DataAPI<Sample, SampleDTO> {
  constructor(token: string) {
    super("samples", token)
  }
  async bulkInsert(sampleDTOs: SampleDTO[]): Promise<SampleBulkInsertResponse> {
    return await this.fetchJson(`${this.baseUrl}/bulkinsert`, 'POST', JSON.stringify(sampleDTOs))
  }
  async bulkDelete(sampleIds: string[]) {
    return await this.fetchJson(`${this.baseUrl}/bulkdelete`, 'POST', JSON.stringify(sampleIds))
  }
  async query(query: ViewQuery, limit: number): Promise<Sample[]> {
    const payload = {
      ...query,
      limit: limit
    }
    return await this.fetchJson(`${this.baseUrl}/query`, 'POST', JSON.stringify(payload))
  }
  async queryById(sampleIds: string[], organization: string, limit?: number, offset?: number, signal?: AbortSignal): Promise<Sample[]> {
    return await this.fetchJson(`${this.baseUrl}/querybyid`, 'POST', JSON.stringify({ sampleIds, limit, offset, organization }), signal)
  }
  async queryById_ndb(sampleIds: string[], limit: number, offset: number, signal?: AbortSignal): Promise<Sample[]> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/querybyid_ndb?${params.toString()}`, 'POST', JSON.stringify({ sampleIds, limit, offset }), signal)
  }
  async query_ndb(queryString: string, page?: number, size?: number, sort?: string, cursor?: string, isOutbreak?: boolean, withTotalCount?: boolean, ): Promise<SearchResponse> {
    let params = new URLSearchParams()
    if (page !== undefined)
      params.set("page", page.toString())
    if (size)
      params.set("size", size.toString())
    if (sort)
      params.set("sort", sort)
    if (cursor)
      params.set("cursor", cursor)
    if (isOutbreak)
      params.set("isOutbreak", isOutbreak.toString())
    if (withTotalCount !== undefined)
      params.set("withTotalCount", withTotalCount.toString())
    params.set("organization", currentOrg())

    return await this.fetchJson(`${this.baseUrl}/query_ndb${params.toString() ? `?${params.toString()}` : ""}`, 'POST', queryString)
  }
  async search(query: ViewQuery, page?: number, size?: number, sort?: string, cursor?: string): Promise<SearchResponse> {
    let params = new URLSearchParams()
    params.set("organism", query.organism)
    params.set("organization", query.organization)
    if (page !== undefined)
      params.set("page", page.toString())
    if (size)
      params.set("size", size.toString())
    if (sort)
      params.set("sort", sort)
    if (cursor)
      params.set("cursor", cursor)

    return await this.fetchJson(
      `${this.baseUrl}/search/${params.toString() ? `?${params.toString()}` : ""}`,
      'POST',
      JSON.stringify(query)
    )
  }
  async getNdbById(sampleId: string): Promise<Sample> {
    let params = new URLSearchParams();    
    params.set("organization", currentOrg()) 
    return await this.fetchJson(`${this.baseUrl}/ndb/${sampleId}?${params.toString()}`, 'GET');
  }
  async getByKey(key: string): Promise<Sample | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/getbyidentifier/${key}?${params}`, 'GET')
  }
  async getNdbByKey(key: string): Promise<Sample | null> {
    let params = new URLSearchParams();    
    params.set("organization", currentOrg()) 
    return await this.fetchJson(`${this.baseUrl}/ndb/identifier/${key}?${params}`, 'GET')
  }
  async getAnalyses(sampleId: string, signal?: AbortSignal): Promise<any | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/analyses2?${params}`, 'GET', undefined, signal)
  }
  async getAnalysesNdb(sampleId: string): Promise<any | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/ndb/${sampleId}/analyses?${params}`, 'GET')
  }
  async getAnalysisById(sampleId: string, analysisId: string): Promise<SampleAnalysis | null> {
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/analyses2/${analysisId}`, 'GET')
  }
  async getAnalysisByURL(url: string): Promise<SampleAnalysis | null> {
    return await this.fetchJson(url, 'GET')
  }
  async getPipelineRunBySampleId(sampleId: string): Promise<PipelineRun | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/pipelinerun?${params}`, 'GET')
  }
  async publishSample(sampleId: string): Promise<Sample | null> {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/publish_ndb?${params}`, 'POST', undefined, undefined, {"X-Timezone": userTimeZone})
  }
  async publishSamples(sampleIds: string[]): Promise<Sample | null> {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/publish_multiplesamples_ndb?${params}`, 
      'POST', JSON.stringify(sampleIds), undefined, {"X-Timezone": userTimeZone})
  }
  async confirmSamples(sampleIds: string[]): Promise<String | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/confirm_multiplesamples_ndb?${params}`, 'POST', JSON.stringify(sampleIds))
  }
  async unconfirmSamples(sampleIds: string[]): Promise<String | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/unconfirm_multiplesamples_ndb?${params}`, 'POST', JSON.stringify(sampleIds))
  }
  async GetAnalysesByComparedSamples(comparedSamples: ComparedSample[], analysisName: string): Promise<ComparisonWithAnalysis | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/querybyid/analyses/${analysisName}?${params}`, 'POST', JSON.stringify(comparedSamples))
  }
   async GetAllAnalysesByComparedSamples(comparedSamples: ComparedSample[], signal?: AbortSignal):  Promise<ComparedSampleAnalysis[]> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/querybyid/analyses?${params}`, 'POST', JSON.stringify(comparedSamples), signal)
  }

  async GetAnalysesByProcessName(sampleId: string, processName: string): Promise<SampleAnalysis | null> {
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/analyses2/${processName}`, 'GET')
  }
  async GetUpgmaTreeByComparedSamples(comparedSamples: ComparedSample[], algorithm?: string, distance?: string, organism?: string, mlstScheme?: string, maxTreeHeight?: number, collapeseZeroLengthNodes?: boolean, signal?: AbortSignal): Promise<any | null> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg())
    if (algorithm)
      params.set("algorithm", algorithm)
    if (distance)
      params.set("distance", distance)
    if (organism)
      params.set("organism", organism)
    if (mlstScheme)
      params.set("MLSTScheme", mlstScheme)
    if (maxTreeHeight)
      params.set("maxTreeHeight", maxTreeHeight.toString())
    if (typeof collapeseZeroLengthNodes === "boolean")
      params.set("collapeseZeroLengthNodes", collapeseZeroLengthNodes.toString())

    return await this.fetchJson(
      `${this.baseUrl}/querybyid/analyses/upgma_tree${params.toString() ? `?${params.toString()}` : ""}`,
      'POST',
      JSON.stringify(comparedSamples),
      signal
    )
  }

  async GetAlignmentByComparedSamples(comparedSamples: ComparedSample[], alignmentblockType: string | null, signal?: AbortSignal): Promise<any | null> {
    let params = new URLSearchParams()
    params.set("organizationName", currentOrg())
    if (alignmentblockType)
      params.set("alignmentblockType", alignmentblockType)

    return await this.fetchJson(
      `${this.baseUrl}/querybyid/analyses/alignment${params.toString() ? `?${params.toString()}` : ""}`,
      'POST',
      JSON.stringify(comparedSamples),
      signal
    )
  }
  async GetProfileMatches(sampleId: string, threshold: number, organism: string, distanceType: string, matchesMaxNumber: number, nationalDatabase: boolean, organization: string = "CDC", signal?: AbortSignal): Promise<any | null> {
    let params = new URLSearchParams();
    params.set("threshold", threshold.toString());
    params.set("organism", organism);
    params.set("distanceType", distanceType);
    params.set("matchesMaxNumber", matchesMaxNumber.toString());
    params.set("nationalDatabase", nationalDatabase.toString());
    params.set("organizationName", currentOrg())
    if (organization) {
      params.set("organization", organization);
    }

    return await this.fetchJson(
      `${this.baseUrl}/${sampleId}/match_profile?${params.toString()}`,
      'GET',
      undefined,
      signal
    )
  }
  async matchProfiles(body: ProfileMatchingBody, signal?: AbortSignal) {
    let params = new URLSearchParams();
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/match_profiles?${params.toString()}`, 'POST', JSON.stringify(body), signal) as ProfileMatchingResponse[]
  }
  async updateNDBMetadata(sampleId: string, sampleMetadata: SampleMetadata, signal?: AbortSignal) {
    let params = new URLSearchParams();
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/ndb/${sampleId}/metadata?${params.toString()}`, 'PUT', JSON.stringify(sampleMetadata), signal) as boolean[]
  }
  async getProcessesById(sampleId: string, original?: boolean, signal?: AbortSignal) {
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/processes${ original ? `?original=true` : "" }`, 'GET', undefined, signal) as SampleProcesses
  }
  async getNCBIProcessesById(sampleId: string, signal?: AbortSignal) {
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/ncbi_processes`, 'GET', undefined, signal) as SampleProcesses
  }
  async findClusters(wgstTier: number, minClusterSize: number, nonHumanSources: boolean, pastDays: number, organism: string, signal?: AbortSignal) {
    let params = new URLSearchParams();
    params.set("wgstTier", wgstTier.toString());
    params.set("minClusterSize", minClusterSize.toString());
    params.set("nonHumanSources", nonHumanSources.toString());
    params.set("pastDays", pastDays.toString());
    params.set("organism", organism.toString());
    params.set("organization",currentOrg());
    return await this.fetchJson(`${this.baseUrl}/ndb/findClusters?${params.toString()}`, 'GET', undefined, signal) as FindClustersResult[]
  }
  async unpublishFromNdb(sampleId: string): Promise<string> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/unpublishFromNdb/${sampleId}?${params.toString()}`, 'DELETE')
  }
  async updateIdentifier(sampleId: string, body: UpdateIdentifierBody): Promise<any | null> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/updateIdentifier?${params.toString()}`, 'PUT', JSON.stringify(body))
  }
  async bulkLinkFiles(body: BulkLinkFileBody[]): Promise<void> {
    return await this.fetchJson(`${this.baseUrl}/filelink/bulklinkfiles?`, 'POST', JSON.stringify(body))
  }
  async getByLabAccessionNumber(labAccessionNumber: string): Promise<Sample | null> {
    return await this.fetchJson(`${this.baseUrl}/getbylabaccessionnumber/${labAccessionNumber}`, 'GET')
  }
  async deleteFileLinksBySampleIdentifier(identifier: string): Promise<void> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${identifier}/filelinks?${params}`, 'DELETE')
  }
  async updateTraces(sampleId: string, editedTraces: editedTraces[], signal?: AbortSignal): Promise<boolean> {
    return await this.fetchJson(`${this.baseUrl}/${sampleId}/traces`, 'PUT', JSON.stringify(editedTraces), signal) as boolean
  }
}

export interface UpdateIdentifierBody {
  identifier: string
}

export class ViewAPI extends DataAPI<View, ViewDTO> {
  constructor(token: string) {
    super("views", token)
  }
  async getViewList(viewIdList: string[], organism: string): Promise<View[]> {
    let params = new URLSearchParams();
    params.set("organism", organism.toString());
    return await this.fetchJson(`${this.baseUrl}/getviewlist?${params.toString()}`, 'POST', JSON.stringify(viewIdList))
  }

  async getViewsByOrganism(organism: string): Promise<View[]> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    params.set("organism", organism.toString());
    return await this.fetchJson(`${this.baseUrl}?${params.toString()}`, 'GET');
  }
}

export class PipelineAPI extends DataAPI<Pipeline, PipelineDTO> {
  constructor(token: string) {
    super("pipelines", token)
  }
  async getPipelines(organizationName: string,): Promise<Pipeline[]> {
    let params = new URLSearchParams()
    if (organizationName)
      params.set("organization", organizationName)
    return await this.fetchJson(`${this.baseUrl}${params.toString() ? `?${params.toString()}` : ""}`, 'GET')
  }
  async executePipeline(pipelineId: string, pipelineExecutionRequest: PipelineExecutionRequest): Promise<PipelineRun> {
    return await this.fetchJson(`${this.baseUrl}/${pipelineId}/executepipeline`, 'POST', JSON.stringify({
      ...pipelineExecutionRequest
    }))
  }
}

export class PipelineRunAPI extends DataAPI<PipelineRun, PipelineRunDTO> {
  constructor(token: string) {
    super("pipelineruns", token)
  }
  async getWeblogs(pipelineRunId: string,): Promise<Weblog[]> {
    return await this.fetchJson(`${this.baseUrl}/${pipelineRunId}/weblog`, 'GET')
  }
  async restartPipeline(pipelineRunId: string): Promise<PipelineRun> {
    return await this.fetchJson(`${this.baseUrl}/${pipelineRunId}/restart`, 'POST')
  }
  async cancelPipeline(pipelineRunId: string): Promise<PipelineRun> {
    return await this.fetchJson(`${this.baseUrl}/${pipelineRunId}/cancel`, 'POST')
  }
  async getOutputFile(pipelineId: string, sampleIdentifier: string, path: string): Promise<any> {
    let params = new URLSearchParams()
    params.set("path", path);
    params.set("organizationName", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${pipelineId}/output-file?${params.toString()}`, 'GET')
  }
}

export class ComparisonAPI extends DataAPI<Comparison, ComparisonDTO> {
  constructor(token: string) {
    super("comparisons", token)
  }
  async getComparedDataByIdAndName(comparisonId: string, analysesName: string): Promise<ComparisonWithAnalysis> {
    let params = new URLSearchParams();    
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${comparisonId}/analyses/${analysesName}?${params}`, 'GET')
  }
  async getAnalysesById(comparisonId: string, signal?: AbortSignal): Promise<string[]> {
    return await this.fetchJson(`${this.baseUrl}/${comparisonId}/analyses`, 'GET', undefined, signal)
  }
  async getUpgmaTree(comparisonId: string, algorithm?: string, distance?: string, signal?: AbortSignal): Promise<any> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg())
    if (algorithm)
      params.set("algorithm", algorithm)
    if (distance)
      params.set("distance", distance)
    return await this.fetchJson(`${this.baseUrl}/${comparisonId}/upgma_tree${params.toString() ? `?${params.toString()}` : ""}`, 'GET', undefined, signal)
  }
  async getComparisonList(comparisonIdList: string[], organism: string): Promise<Comparison[]> {
    let params = new URLSearchParams();
    params.set("organism", organism.toString());
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/getcomparisonlist?${params.toString()}`, 'POST', JSON.stringify(comparisonIdList))
  }
  async getComparisonsByOrganism(organism: string): Promise<Comparison[]> {
    let params = new URLSearchParams();
    params.set("organism", organism.toString());
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}?${params.toString()}`, 'GET');
  }
  async updateMLTree(comparisonId: string, megaCCRequest: MegaCCRequest): Promise<any> {
    return await this.fetchJson(
      `${this.baseUrl}/${comparisonId}/ml_tree`,
      'PUT',
      JSON.stringify(megaCCRequest),
      )
   }
}

export class EventAPI extends DataAPI<PipelineRunEvent, PipelineRunEventDTO> {
  constructor(token: string) {
    super("events", token)
  }
  async getEvents(): Promise<PipelineRunEvent[]> {
    return await this.fetchJson(`${this.baseUrl}`, 'GET')
  }

  async updateEvents(): Promise<any> {
    //return await this.fetchJson(`${this.baseUrl}/${userId}`, 'PUT')
    return await this.fetchJson(`${this.baseUrl}`, 'PUT')
  }
  async updateEventById(eventId: string): Promise<any> {
    return await this.fetchJson(`${this.baseUrl}/${eventId}/updateEvent`, 'PUT')
  }
}

export class OrganismAPI extends DataAPI<Organism, OrganismDTO> {
  constructor(token: string) {
    super("organisms", token)
  }

  async getOrganismByName(organizationName: string, organismName: string): Promise<Organism> {
    return JSON.parse(await this.fetchJson(`${this.baseUrl}/${organizationName}/${organismName}`, 'GET'));
  }
}

export class OutbreakAPI extends DataAPI<Outbreak, OutbreakDTO> {
  constructor(token: string) {
    super("outbreaks", token)
  }

  async getNextKey(year: number, type: string): Promise<any> {
    let params = new URLSearchParams();
    params.set("year", year.toString());
    params.set("type", type);
    return await this.fetchJson(`${this.baseUrl}/getnextkey?${params.toString()}`, 'GET');
  }

  async getByKey(key: string): Promise<Outbreak | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/getbyidentifier/${key}?${params}`, 'GET')
  }

  async bulkInsert(outbreakDTOs: OutbreakDTO[]): Promise<OutbreakBulkInsertResponse> {
    return await this.fetchJson(`${this.baseUrl}/bulkinsert`, 'POST', JSON.stringify(outbreakDTOs))
  }

  async bulkDelete(outbreakIds: string[]) {
    return await this.fetchJson(`${this.baseUrl}/bulkdelete`, 'POST', JSON.stringify(outbreakIds))
  }

  async getNdbByKey(key: string): Promise<Outbreak | null> {
    let params = new URLSearchParams();    
    params.set("organization", currentOrg()) 
    return await this.fetchJson(`${this.baseUrl}/ndb/identifier/${key}?${params}`, 'GET')
  }

  async search(query: ViewQuery, page?: number, size?: number, sort?: string, cursor?: string): Promise<SearchResponse> {
    let params = new URLSearchParams()
    params.set("organism", query.organism)
    if (page !== undefined)
      params.set("page", page.toString())
    if (size)
      params.set("size", size.toString())
    if (sort)
      params.set("sort", sort)
    if (cursor)
      params.set("cursor", cursor)

    return await this.fetchJson(
      `${this.baseUrl}/search/${params.toString() ? `?${params.toString()}` : ""}`,
      'POST',
      JSON.stringify(query)
    )
  }

  async publishOutbreak(outbreakId: string): Promise<Outbreak | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/${outbreakId}/publish_ndb?${params}`, 'POST')
  }
  async publishOutbreaks(outbreakIds: string[]): Promise<Outbreak | null> {
    let params = new URLSearchParams();
    params.set("organization", currentOrg())
    return await this.fetchJson(`${this.baseUrl}/publish_multipleoutbreaks_ndb?${params}`, 'POST', JSON.stringify(outbreakIds))
  }
  async getNdbById(outbreakId: string): Promise<Outbreak> {
    let params = new URLSearchParams();    
    params.set("organization", currentOrg()) 
    return await this.fetchJson(`${this.baseUrl}/ndb/${outbreakId}?${params.toString()}`, 'GET');
  }
  async updateNDBMetadata(outbreakId: string, outbreakMetadata: OutbreakMetadata, signal?: AbortSignal) {
    let params = new URLSearchParams();
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/ndb/${outbreakId}/metadata?${params.toString()}`, 'PUT', JSON.stringify(outbreakMetadata), signal) as boolean[]
  }
  async unpublishOutbreakFromNdb(outbreakId: string): Promise<string> {
    let params = new URLSearchParams()
    params.set("organization", currentOrg());
    return await this.fetchJson(`${this.baseUrl}/unpublishOutbreakFromNdb/${outbreakId}?${params.toString()}`, 'DELETE')
  }
}

export class OrganizationAPI extends DataAPI<Organization, OrganizationDTO> {
  constructor(token: string) {
    super("organizations", token)
  }

  async getOrganizationByName(organizationName: string): Promise<Organization> {
    return await this.fetchJson(`${this.baseUrl}/organizationName/${organizationName}`, 'GET');
  }
}

export class LabAPI extends DataAPI<Lab, LabDTO> {
  constructor(token: string) {
    super("labs", token)
  }

  async createPipelineTemplate(template: PipelineTemplate, labId: string): Promise<void> {
    return await this.fetchJson(`${this.baseUrl}/${labId}/PipelineTemplates`, 'POST', JSON.stringify({ ...template }));
  }
  async updatePipelineTemplate(template: PipelineTemplate, labId: string, originalName: string) {
    return await this.fetchJson(`${this.baseUrl}/${labId}/PipelineTemplates/${originalName}`, 'PUT', JSON.stringify({ ...template }));
  }
  async deletePipelineTemplate(templateName: string, labId: string): Promise<void> {
    return await this.fetchJson(`${this.baseUrl}/${labId}/PipelineTemplates/${templateName}`, 'DELETE');
  }
}