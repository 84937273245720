import { useState } from "react";
import { SelectedFile } from "./StartPipelineDialog";
import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { Elevation } from "@blueprintjs/core/lib/esm/common/elevation";
import { H4, H5 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Checkbox } from "@blueprintjs/core/lib/esm/components/forms/controls";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner";
import { SelectedSample } from "./FileSelectBaseSpace";
import { Dialog, NonIdealState } from "@blueprintjs/core";
import { useStartPipelineDialogStore } from "../../stores/StartPipelineDialogStore";

interface NCBISRASample {
  title: string,
  acc: string,
  samn: string,
  alias: string
  size: number
  published: Date
  srr_uri: string
}

export const FileSelectNCBISRA = () => {
  const { selectedFiles, setSelectedFiles, totalSelectedSamples, setTotalSelectedSamples } = useStartPipelineDialogStore()
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<NCBISRASample[]>([]);
  const [selectedNCBISRASamples, setSelectedNCBISRASamples] = useState<NCBISRASample[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchDirty, setSearchDirty] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [retstart, set_retstart] = useState(0);
  const [count, setCount] = useState<number>();

  const searchSRA = async (retstartInc?: number) => {
    setSearchLoading(true)
    setSearchDirty(true)
    try {
      let new_retstart = retstartInc ? retstart + retstartInc : 0
      const response = await fetch(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=sra&retmode=json&term=${searchText}&retmax=20&retstart=${new_retstart}&api_key=b42537464084f75e67488d6e5a15544b5207`)
      const jsonResponse = await response.json()
      setCount(jsonResponse?.esearchresult?.count)
      const idList = jsonResponse.esearchresult.idlist as string[]
      const fetchResponse = await fetch(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=sra&id=${idList.join(",")}&api_key=b42537464084f75e67488d6e5a15544b5207`)
      const fetchTextResult = await fetchResponse.text()
      const fetchDom = new window.DOMParser().parseFromString(fetchTextResult, "text/xml")
      const runs = fetchDom.getElementsByTagName("RUN")
      const runsArr = [].slice.call(runs) as Element[];
      const samples = runsArr
        .filter(v => !!v.querySelectorAll('[semantic_name="SRA Normalized"]')[0]?.getAttribute("url"))
        .map(v => ({
          title: v.getElementsByTagName("Member")[0]?.getAttribute("sample_title") ?? "",
          acc: v.getAttribute("accession") ?? "",
          samn: v.querySelectorAll("[namespace='BioSample']")[0]?.innerHTML ?? "",
          alias: v.getAttribute("alias") ?? "",
          size: parseInt(v.getAttribute("size") ?? ""),
          published: new Date(v.getAttribute("published") ?? ""),
          srr_uri: v.querySelectorAll('[semantic_name="SRA Normalized"]')[0]?.getAttribute("url")
        }) as NCBISRASample)
      setSearchResults(samples)
      set_retstart(new_retstart)
    }
    catch (e: any) {
      if (e.message) {
        setShowErrorDialog(true)
        setErrorMessage(e?.message ?? "An unknow error has occurred.")
      }
    }
    setSearchLoading(false)
  }


  const removeSelectedSample_onClick = (sample: SelectedSample) => {
    const newSelectedFiles = [...selectedFiles.filter(v => v.name !== sample.name)]
    setSelectedFiles(newSelectedFiles)
    const newTotalSelectedSamples = [...totalSelectedSamples.filter(v => v !== sample)]
    setTotalSelectedSamples(newTotalSelectedSamples)
  }

  const addSelectedSamplesToTotal = (input: NCBISRASample) => {
    const selectedSamples = [input].map(v => ({
      name: `${v.acc}`,
      srr: v.acc,
      project: v.title,
      files: [{
        fileLink: v.srr_uri,
        fileName: v.acc
      }],
      ncbiMetadata: {
        SRR_ID: v.acc,
        NCBI_ACCESSION: v.samn
      }
    })) as SelectedSample[]
    // const totalSelectedSamples
    const totalSelected = [...totalSelectedSamples, ...selectedSamples]
    setTotalSelectedSamples(totalSelected)
    setSelectedFiles(totalSelected.map(v => ({
      name: v.name,
      source: "sra",
      fileType: "sra",
      ncbiMetadata: v.ncbiMetadata,
      remoteFileUrl: v.files[0].fileLink
    }) as SelectedFile))
    setSelectedNCBISRASamples([])
  }


  return <>

    <div style={{ display: "flex", gap: 10 }}>

      <div style={{ flex: 1 }}>
        <div style={{ display: "flex", gap: 5 }}>
          <div className="bp4-input-group .modifier">
            <span className="bp4-icon bp4-icon-search"></span>
            <input id="viewSearchBar" type="text" className="bp4-input " style={{ margin: 2 }} placeholder="Search NCBI SRA" defaultValue={searchText}
              onKeyUp={(e) => {
                if (e.key === "Enter")
                  searchSRA(0)
              }}
              onChange={(e) => {
                setSearchText(e.currentTarget.value)
              }} />
          </div>
          <Button onClick={() => searchSRA(0)}>Search</Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5, height: "370px", overflowY: "auto", padding: "5px" }}>
          {searchLoading ?
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Spinner></Spinner>
              <H5>Searching NCBI SRA...</H5>
            </div>
            : searchResults.map((result, i) => <Card
              key={i}
              elevation={Elevation.ONE}
              style={{ padding: 10 }}
            >
              <div style={{ display: "flex", gap: 5, justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 2 }}>
                  <Checkbox
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setSelectedNCBISRASamples(selectedNCBISRASamples.includes(result) ? selectedNCBISRASamples.filter(v => v !== result) : [...selectedNCBISRASamples, result])
                        addSelectedSamplesToTotal(result)
                      }
                    }}
                    disabled={totalSelectedSamples.map(v => v.name).includes(result.acc)}
                    checked={!!selectedNCBISRASamples.filter(sample => sample.acc === result.acc)[0] || totalSelectedSamples.map(v => v.name).includes(result.acc)}
                    onClick={() => {
                      setSelectedNCBISRASamples(selectedNCBISRASamples.includes(result) ? selectedNCBISRASamples.filter(v => v !== result) : [...selectedNCBISRASamples, result])
                      addSelectedSamplesToTotal(result)
                    }} />
                  <H5><Icon icon="lab-test"></Icon> {result.acc}</H5>
                </div>
                <div>{result.alias}</div>
              </div>
              <div style={{ display: "flex", gap: 5, justifyContent: "space-between" }}>
                <div>Size: {(result.size / 1000 / 1000).toFixed(2)}MB</div>
                <div>Published: {result.published.toDateString()}</div>
              </div>
            </Card>)}
          {!searchDirty || searchResults.length || searchLoading ? null :
            <NonIdealState
              icon="search"
              title="No items found."
              description="For more details on searching the NCBI SRA database check the documentation at https://www.ncbi.nlm.nih.gov/sra/docs/srasearch/ under the 'Basic search in SRA' section. "
            ></NonIdealState>
          }
        </div>
        {searchResults.length && !searchLoading ? <div style={{ display: "flex", gap: 5, justifyContent: "space-between", alignItems: "center" }}>
          <div>Displaying {retstart + 1} to {retstart + 20 < (count ?? 0) ? retstart + 20 : count} of {count} results.</div>
          <div style={{ display: "flex", gap: 5, }}>
            {retstart < 1 ? null : <Button icon="arrow-left" title="Pevious Page" onClick={() => searchSRA(-20)}></Button>}
            {count && retstart + 20 < count ? <Button icon="arrow-right" title="Next Page" onClick={() => searchSRA(20)}></Button> : null}
          </div>
        </div> : null}
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: "column" }}>
        <H4>{totalSelectedSamples.length} Selected Samples</H4>
        <div style={{ display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
          {totalSelectedSamples.map((sample, i: number) =>
            <Card
              key={i}
              elevation={Elevation.ONE}
              style={{ padding: 10, display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <H5><Icon icon="lab-test"></Icon> {sample.ncbiMetadata?.SRR_ID}</H5>
              </div>
              <Button icon="trash" minimal onClick={() => removeSelectedSample_onClick(sample)}></Button>
            </Card>)}
        </div>
      </div>
    </div>
    <Dialog
      isOpen={showErrorDialog}
      title="Error Searching NCBI SRA"
      icon="error"
      onClose={() => setShowErrorDialog(false)}
      style={{ width: "512px" }}
    >
      <div className="bp3-dialog-body" style={{ textAlign: "left", margin: "15px" }}>
        {errorMessage}
      </div>
      <div className="bp3-dialog-footer" style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginRight: "10px" }}>
          <Button intent="danger" onClick={() => setShowErrorDialog(false)}>Close</Button>
        </div>
      </div>
    </Dialog>
  </>
}