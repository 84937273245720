import { Card, ProgressBar, Icon, Button, Spinner, Switch, HTMLSelect } from "@blueprintjs/core"
import { FileSource, SelectedFile } from "./StartPipelineDialog"
import { useEffect, useState } from "react"
import { useAppStore } from "../../stores/AppStore"
import { getPatternValue } from "../../utils/getPatternValue"
import { usePatternStore } from "../../stores/PatternStore"
import { PipelineExecutionRequest } from "../../types/PipelineType"
import { useAuth } from "react-oidc-context"
import { SampleAPI, PipelineAPI } from "../../api/DataAPI"
import { startUpload } from "../../api/uploadAPI"
import { SampleDataMetadata, SelectedFileWithMetadata, ValidatedSample, useStartPipelineDialogStore } from "../../stores/StartPipelineDialogStore"
import { BulkLinkFileBody, InputFileLink, Sample } from "../../types/SampleTypes"
import { Toaster } from "../../utils/Toaster"
import { Popover2 } from "@blueprintjs/popover2"
import { useOrganismStore } from "../../stores/OrganismStore"
import { useOrganizationStore } from "../../stores/OrganizationStore"
import { usePipelinesStore } from "../../stores/PipelinesStore"
import { useLabsStore } from "../../stores/LabsStore"
import { useStartPipelineOnSelectedFunctions } from "../../hooks/useStartPipelineOnSelectedFunctions"
import { usePipelineRunsDialogFunctions } from "../../hooks/usePipelineRunsDialogFunctions"

interface TrackedSample {
  Key: string;
  SEQUENCERRUN_ID?: string;
  trackedFiles: SelectedFile[];
  data: SampleDataMetadata
  source: FileSource
  sampleStatus?: string
  error?: string
}

export const StartPipelineStep = () => {
  const { selectedFiles, source,
    setUploadComplete,
    filesUploading, setFilesUploading,
    showPipelineRunStatus,
    pipelineRun, setPipelineRun,
    currentFile, setCurrentFile,
    validatedSamples, setValidatedSamples,
    samples, setSamples,
    linkingFiles, setLinkingFiles,
    setinitialStepIndex,
    linkingComplete, setLinkingComplete,
    startPipeline, setStartPipeline,
    selectedPipelineId, setSelectedPipelineId,
    selectedTemplateName, setSelectedTemplateName,
    requiredTemplateSelected, setRequiredTemplateSelected
  } = useStartPipelineDialogStore()
  const { setShowPipelineRunsDrawer } = useAppStore()
  const { patterns } = usePatternStore();
  const { organism } = useOrganismStore();
  const { organization } = useOrganizationStore();
  // const { loadView } = useDataViewerCtrFunctions()
  const { pipelines } = usePipelinesStore()
  const { userLab } = useLabsStore()
  const { getSampleMappings } = useStartPipelineOnSelectedFunctions()
  const {loadDialog: loadPipelineRunDialog} = usePipelineRunsDialogFunctions()

  const [showDetailsView, setShowDetailsView] = useState(false)

  const auth = useAuth();
  if (!auth.user && !(process.env["REACT_APP_OVERRIDE_AUTH"] === "true"))
    throw new Error("No authenticated user found.")
  const samplesAPI = new SampleAPI(auth.user?.access_token ?? "")
  const pipelineAPI = new PipelineAPI(auth.user?.access_token ?? "")

  useEffect(() => {    
    if(selectedFiles[0]?.fileType){
      setinitialStepIndex(2)
      pipelines?.length && setSelectedPipelineId(pipelines.filter(v => v.canStartOnLinkFile && v.fileType === selectedFiles[0].fileType)[0].id)      
    }        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(() => {
    const samples = convertFilesToTrackedSamples(selectedFiles)
    if (samples.length !== validatedSamples.length)
      validateSampleKeys(samples)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  useEffect(() => {
    const selectedPipeline = pipelines?.find(v => v.id === selectedPipelineId)
    const templateNotSelected = selectedPipeline?.requiresTemplate === true && (!selectedTemplateName || selectedTemplateName === "")
    setRequiredTemplateSelected(!templateNotSelected)
  }, [linkingFiles, selectedPipelineId])

  const validateSampleKeys = async (samples: TrackedSample[]) => {
    const validatedSamples = await Promise.all(samples.map(async v => {
      let validatedSample: ValidatedSample = {
        Key: v.Key
      }
      try {
        let sample = organization?.useLabAccession ? await samplesAPI.getByLabAccessionNumber(v.Key) : await samplesAPI.getByKey(v.Key)
        if (sample)
          validatedSample.exists = true
      }
      catch (e: any) {
        if (e.message && JSON.parse(e.message).status !== 404)
          throw e
        validatedSample.exists = false
      }
      return validatedSample
    }))
    setValidatedSamples(validatedSamples)
  }

  const getIdentifierFromFileName = (fileName: string) => {
    const KeyPattern = patterns.filter(v => v.field === "Key")[0]
    const KeyValue = getPatternValue(KeyPattern.pattern, KeyPattern.patternType, fileName)
    return KeyValue
  }

  function isValidFileName(fileName: string): boolean {
    return /^[a-zA-Z0-9\-_\.]+$/.test(fileName);
  }

  const getFileNameParts = (fileName: string) => {
    if (source === "sra") {

    }
    const KeyPattern = patterns.filter(v => v.field === "Key")[0]
    const SRIDPattern = patterns.filter(v => v.field === "SEQUENCERRUN_ID")[0]
    const KeyValue = getPatternValue(KeyPattern.pattern, KeyPattern.patternType, fileName)
    if (!KeyValue)
      throw new Error("No Key pattern found.")

    const fields = {
      metadata: {
        Key: KeyValue,
        SEQUENCERRUN_ID: SRIDPattern ? getPatternValue(SRIDPattern.pattern, SRIDPattern.patternType, fileName) : ""
      }
    }


    return fields as SampleDataMetadata
  }

  const convertFilesToTrackedSamples = (files: SelectedFile[]) => {
    const trackedSamples = files.reduce((pv, cv) => {
      try {
        const fileNameParts: SampleDataMetadata = cv.source === "sra" ? {
          metadata: {
            Key: cv.name,
            SEQUENCERRUN_ID: "",
            ...cv.ncbiMetadata
          },
        } : getFileNameParts(cv.name)
        fileNameParts.metadata.SEQUENCERRUN_ID = cv.sequencerrun_id ? cv.sequencerrun_id : fileNameParts.metadata.SEQUENCERRUN_ID

        let trackedSamples = []
        const trackedSample = pv.filter(v => v.Key === fileNameParts.metadata.Key)[0]
        if (trackedSample)
          trackedSamples = [
            ...pv.filter(v => v.Key !== fileNameParts.metadata.Key),
            {
              ...trackedSample,
              trackedFiles: [
                ...trackedSample.trackedFiles,
                cv
              ],
              data: fileNameParts,
              source: cv.source
            }
          ]
        else
          trackedSamples = [...pv, {
            ...fileNameParts.metadata,
            trackedFiles: [cv],
            data: fileNameParts,
            source: cv.source
          }]
        return trackedSamples
      }
      catch (e) {
        return pv
      }
    }, [] as TrackedSample[])
    trackedSamples.map(v => {
      if (v.source !== "sra") {
        if (v.trackedFiles.length === 0) {
          v.error = "No file detected for sample. Pipeline submission requires a pair of FASTQ files or a FASTA, AB1 or SCF file."
        } else if (v.trackedFiles.length === 1 && !(v.trackedFiles[0].fileType === "assembly" || v.trackedFiles[0].fileType === "sanger")) {
          v.error = "Too few files detected. Pipeline submission requires two paired FASTQ files."
        } else if (v.trackedFiles.length === 2 && !((v.trackedFiles[0].fileType === "paired" && v.trackedFiles[1].fileType === "paired") || (v.trackedFiles[0].fileType === "sanger" && v.trackedFiles[1].fileType === "sanger"))) {
          v.error = "Mismatched files detected. Pipeline submission only requires two files for FASTQ files."
        } else if (v.trackedFiles.length > 2 && (v.trackedFiles[0].fileType !== "sanger")) {
          v.error = "Too many files detected. Pipeline submission requires two paired FASTQ files or a FASTA."
        } else if (v.trackedFiles.some(file => !isValidFileName(file.name))) {
          v.error = "Spaces and special characters are not allowed in the file name with exceptions for dashes and underscores."
        }
      }
      return v
    })
    return trackedSamples as TrackedSample[]
  }

  const getSelectedFilesWithMetadata = (): SelectedFileWithMetadata[] => {
    return selectedFiles.map(v => ({
      ...v,
      identifier: organization?.useLabAccession ? getFileNameParts(v.name).metadata.Key : (v.source === "sra" ? v.name : getIdentifierFromFileName(v.name)),
      data: v.source === "sra" ? {
        metadata: {
          Key: v.name,
          SEQUENCERRUN_ID: "",
          ...v.ncbiMetadata
        },
      } : getFileNameParts(v.name)
    }))
  }

  const calculateTotalProgress = (uploadedFiles: number, totalFiles: number, currentProgress: number) => {
    return (uploadedFiles + currentProgress) / totalFiles
  }

  const completedFiles = () => {
    return selectedFiles.filter(v => v.uploaded && v.name !== currentFile?.name)
  }

  const showMinimalView = () => <>
    {validatedSamples.length ? <Card>
      {linkingFiles ?
        filesUploading || linkingFiles ? <>
          <ProgressBar
            intent={filesUploading ? "primary" : "success"}
            animate={filesUploading}
            // value={selectedFiles.filter(v => v.uploaded).length / selectedFiles.length}
            value={calculateTotalProgress(completedFiles().length, selectedFiles.length, currentFile?.fileProgress ?? 0)}
          />
          <div>Total Upload Progress: {(calculateTotalProgress(completedFiles().length, selectedFiles.length, currentFile?.fileProgress ?? 0) * 100).toFixed(0)}%</div>
          <div>Uploaded {selectedFiles.filter(v => v.uploaded).length}/{selectedFiles.length} Files. Linked {samples.reduce((pv, cv) => [...(cv.inputFileLinks ?? [])], [] as InputFileLink[]).filter(v => v.status === "success").length}/{selectedFiles.length} Files.</div>
          {currentFile && currentFile.file && !showDetailsView ? <div>{currentFile.file.name} - {((currentFile.fileProgress ?? 0) * 100).toFixed(0)}%</div> : null}
        </> : null
        : completedFiles().length / selectedFiles.length === 1 ?
          <div>
            <Icon icon={"tick"} intent="success"></Icon> Uploaded {selectedFiles.length} files for {convertFilesToTrackedSamples(selectedFiles).length} samples.
          </div>
          : <div>
            <strong>Click Link Files to..</strong> <br />
            {source === "local" || organization?.organizationName.toLowerCase().includes('calicinet')
              ? <>Upload {selectedFiles.length} files for {convertFilesToTrackedSamples(selectedFiles).length} samples. <br /></>
              : <>Copy {selectedFiles.length} files from {source === "sra" ? "NCBI SRA" : "BaseSpace"} <br /></>}
            {validatedSamples.filter(v => !v.exists).length ? <>Create {validatedSamples.filter(v => !v.exists).length} new sample entries in database. <br /></> : null}
            {validatedSamples.filter(v => v.exists).length ? <>Update {validatedSamples.filter(v => v.exists).length} sample entries in database.<br /></> : null}
            {hasErrors() ? <span style={{ color: "crimson" }}><Icon icon="error" intent="danger"></Icon>Address validation errors to start pipeline.</span> : null}
          </div>
      }

      {showDetailsView ? detailsView() : <Button minimal onClick={() => setShowDetailsView(true)}>Show Details</Button>}
    </Card> : <Card>
      <div style={{ display: "flex", gap: 10 }}>
        <Spinner></Spinner>
        <div>Validating Samples...</div>
      </div>
    </Card>}

    {showPipelineRunStatus ? <div>
      {pipelineRun
        ? <Card><div>
          <div><Icon icon={"tick"} intent="success"></Icon> Pipeline started successfully. </div>
          <div>
            <Button onClick={() => {
              loadPipelineRunDialog(pipelineRun.id)
            }}>View Pipeline</Button>
            <Button onClick={() => setShowPipelineRunsDrawer(true)}>View All Pipelines</Button>
          </div> </div></Card>
        : <Card><Spinner size={20} ></Spinner> Starting Pipeline... </Card>}
    </div> : null}

  </>

  const detailsView = () => <div >
    <div style={{ overflowY: "auto", maxHeight: "350px", padding: "5px", display: "flex", flexDirection: "column", gap: "5px" }}>
      {convertFilesToTrackedSamples(selectedFiles).map((sample, i) => <div style={{ display: "flex", gap: 5, alignItems: "stretch" }} key={i}>
        <Card style={{ display: "flex", gap: 10, padding: 5 }}>
          {validatedSamples.filter(v => v.Key === sample.Key)[0]?.exists
            ? <Icon icon="tick" intent="success" title="Sample Key confirmed in database." />
            : <Icon icon="add" intent="success" title="Sample Key does not exist and will be created." />}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <span style={{ fontWeight: 500 }}>{organization?.useLabAccession ? `Lab Accession Number` : `Key`}: </span>{sample.Key}
            </div>
            {sample.SEQUENCERRUN_ID && <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: 500 }}>SEQUENCERRUN_ID</div>
              <div>{sample.SEQUENCERRUN_ID}</div>
            </div>}
          </div>
        </Card>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {sample.trackedFiles.map((file, i) => <Card key={i} style={{ padding: 5, display: "flex", gap: 10 }}>
            <Icon icon={file.source === "sra" ? "link" : "document"} aria-label={file.source === "sra" ? "link" : "file icon"}></Icon>
            {file.source === "sra" ? `SSR_ID: ${file.ncbiMetadata?.SRR_ID}` : file.name}
            {!!file.fileProgress && file.fileProgress > 0 && <div style={{ display: "flex", gap: 5 }}>
              {file.uploaded ? <Icon icon="tick-circle" size={20} intent="success"></Icon> : <Spinner size={20} value={file.fileProgress} intent={file.fileProgress < 1 ? "primary" : "success"}></Spinner>}
              <div>{(file.fileProgress * 100).toFixed(0)}%</div>
            </div>}
            {samples.find(v => v.identifier === sample.Key)?.inputFileLinks?.find(v => v.name === file.name)?.status === "pending" && <div style={{ display: "flex", gap: 5 }}>
              <Spinner size={20}></Spinner>
              <div>Linking File..</div>
            </div>}
            {samples.find(v => v.identifier === sample.Key)?.inputFileLinks?.find(v => v.name === file.name)?.status === "success" && !filesUploading && <div style={{ display: "flex", gap: 5 }}>
              <Icon icon="link" intent="success"></Icon>
              <div style={{ whiteSpace: "nowrap" }}>File Linked</div>
            </div>}
            {samples.find(v => v.identifier === sample.Key)?.data.metadata[organization?.sraMetadataField ?? ""] && !filesUploading && <div style={{ display: "flex", gap: 5 }}>
              <Icon icon="tick" intent="primary"></Icon>
              <div style={{ whiteSpace: "nowrap" }}>NCBI Linked</div>
            </div>}
            {samples.find(v => v.identifier === sample.Key)?.inputFileLinks?.find(v => v.name === file.name)?.status === "error" &&
              <Popover2
                interactionKind="hover"
                content={<div style={{padding: 10 }}>
                  {samples.find(v => v.identifier === sample.Key)?.inputFileLinks?.find(v => v.name === file.name)?.errorMessage}
                </div>}
              >
                <div style={{ display: "flex", gap: 5}}>
                  <Icon icon="cross-circle" intent="danger"></Icon>
                  <div style={{ whiteSpace: "nowrap" }}>Error Linking File</div>
                </div>
              </Popover2>
            }
          </Card>
          )}
        </div>
        {sample.error ?
          <div>
            <Icon icon="error" intent="danger"></Icon> <span style={{ color: "crimson" }}>Validation Error: {sample.error}</span>
          </div>
          : null}
      </div>)}
    </div>
    <Button minimal onClick={() => setShowDetailsView(false)}>Hide Details</Button>
  </div>

  const linkFilesButton_onClick = async () => {
    try {
      setLinkingFiles(true);
      const trackedSamples = convertFilesToTrackedSamples(selectedFiles)

      //Create or Update sample metadata for files
      let newSamples = await saveSampleMetadata(trackedSamples)
      setSamples(newSamples);

      //upload local files
      const localSelectedFiles = getSelectedFilesWithMetadata().filter(v => v.source === "local")
      if (localSelectedFiles.length) {
        setFilesUploading(true);

        //Run file uploads sync
        for (let item of localSelectedFiles) {
          if (!item.file)
            throw new Error("Selected file is missing local path details.")
          setCurrentFile(localSelectedFiles.filter(v => v.file?.name === item.file!.name)[0])
          await startUpload(item.file, auth, userLab?.labId ?? "CDC")
          let sample = organization?.useLabAccession ? await samplesAPI.getByLabAccessionNumber(item.data.metadata.Key) : await samplesAPI.getByKey(item.data.metadata.Key)
          if (sample) {
            await samplesAPI.update(sample.id, sample)
          }
          const bulkLinkFileBody = {
            // If organization.useLabAccession is true, that means the sample metadata is already uploaded, because the item/filename contains only labAccessionNumber, not sampleKey
            sampleId: organization?.useLabAccession ? sample!.id : newSamples.find(sample => sample.identifier === item.identifier)?.id,
            sampleKey: organization?.useLabAccession ? sample!.identifier : item.identifier,
            source: item.source,
            remoteURI: item.remoteFileUrl,
            fileName: item.name,
            fileType: item.fileType
          } as BulkLinkFileBody
          samplesAPI.bulkLinkFiles([bulkLinkFileBody])
          newSamples = await samplesAPI.queryById(newSamples.map(v => v.id), organization?.organizationName ?? "");
          setSamples(newSamples)
          setLinkingFiles(true)
        }
        setUploadComplete(true)
        setFilesUploading(false);
        // setLinkingFiles(false)
      }
      const basespaceSelectedFiles = getSelectedFilesWithMetadata().filter(v => v.source === "basespace")
      if (basespaceSelectedFiles.length) {
        const basespaceSelectedFilesLinks = await Promise.all(getSelectedFilesWithMetadata().filter(v => v.source === "basespace").map(async v => {
          if (!v.remoteFileUrl)
            throw (new Error("No basespace file content url found for file " + v.name))
          const response = await fetch(v.remoteFileUrl, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          const jsonResponse = await response.json()
          v.remoteFileUrl = jsonResponse.Response.HrefContent
          return v
        }))
        const bulkLinkFilesBody = basespaceSelectedFilesLinks.map(file => ({
          sampleId: newSamples.find(sample => sample.identifier === file.identifier)?.id,
          sampleKey: file.identifier,
          source: file.source,
          remoteURI: file.remoteFileUrl,
          fileName: file.name,
          fileType: file.fileType
        })) as BulkLinkFileBody[]
        await samplesAPI.bulkLinkFiles(bulkLinkFilesBody)
        newSamples = await samplesAPI.queryById(newSamples.map(v => v.id), organization?.organizationName ?? "")
        setSamples(newSamples)
        setLinkingFiles(true)
        // setPipelineStarting(false);
      }
      const sraSelectedFiles = getSelectedFilesWithMetadata().filter(v => v.source === "sra")
      if (sraSelectedFiles.length) {
        setLinkingFiles(false)
        setLinkingComplete(true)
        if (startPipeline) {
          const organismName = organism?.name ?? organization!.defaultOrganism
          const template = userLab?.pipelineTemplates.find(v => v.name === selectedTemplateName && v.organism === organismName && v.organization === organization?.organizationName)
          const startPipelineBody = {
            SampleFileMappings: sraSelectedFiles.map(file => ({
              "SampleKey": file.identifier,
              "FileName": file.name,
              "Source": "sra",
              "RemoteURI": `https://sra-pub-run-odp.s3.amazonaws.com/sra/${file.ncbiMetadata?.SRR_ID}/${file.ncbiMetadata?.SRR_ID}`
            })),
            SampleMetadataMappings: template ? newSamples.map((sample, i) => ({
              "Identifier": sample.identifier,
              "Mappings": getSampleMappings(newSamples, template)[i]
            })) : [],
            organization: organization?.organizationName ?? ""
          } as PipelineExecutionRequest

          Toaster.show({
            icon: "flows", message: (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Spinner size={15}></Spinner>
                <div>Starting Pipeline...</div>
              </div>
            ),
            intent: "primary",
            timeout: undefined
          }, "startSRAPipeline")

          try {
            const pipelineRun = await pipelineAPI.executePipeline(selectedPipelineId, startPipelineBody)
            setPipelineRun(pipelineRun)
            Toaster.dismiss("startSRAPipeline")
            Toaster.show({
              icon: "tick", message: `Pipeline started successfully.`, intent: "success", action: {
                text: "View Pipeline",
                icon: "flows",
                onClick: () => {
                  loadPipelineRunDialog(pipelineRun.id)
                },
              },
              timeout: 10000
            })
          }
          catch (e) {
            Toaster.dismiss("startSRAPipeline")
            Toaster.show({ icon: "error", message: `An error occurred when starting the pipeline.`, intent: "danger" })
          }

        }
      }
    }
    catch (e: any) {
      setLinkingFiles(false);
      // eslint-disable-next-line no-console
      console.error(e)
      Toaster.show({ icon: "error", message: `An error occurred linking files.`, intent: "danger" })
    }
  }

  const saveSampleMetadata = async (trackedSamples: TrackedSample[]) => {
    //create or update each sample for metadata found in file names
    let newSamples = await Promise.all(trackedSamples.map(async v => {      
      let sample: Sample | null;
      try {
        sample = organization?.useLabAccession ? await samplesAPI.getByLabAccessionNumber(v.Key) : await samplesAPI.getByKey(v.Key)
      }
      catch (e: any) {
        if (e.message && JSON.parse(e.message).status !== 404)
          throw e
      }
      if (sample!) {
        sample.data.metadata = { ...v.data.metadata, ...sample.data.metadata}
        if(organization?.organizationName.toLowerCase().includes('calicinet')) {
          sample.data.outbreak = sample.data.outbreak
        }
        await samplesAPI.update(sample.id, sample)
        await samplesAPI.deleteFileLinksBySampleIdentifier(sample.identifier)
        return sample
      }
      else {
        const insertedSample = await samplesAPI.insert({
          identifier: v.Key,
          data: v.data,
          organism: organism?.name ?? organization!.defaultOrganism,
          organization: organization!.organizationName ?? ""
        })
        setValidatedSamples(validatedSamples.map(valSam => {
          if (valSam.Key === v.Key)
            valSam.exists = true
          return valSam
        }))
        await samplesAPI.deleteFileLinksBySampleIdentifier(insertedSample.identifier)
        return insertedSample
      }      
    }))
    // selectedViewId && loadView(selectedViewId)

    return newSamples;
  }

  const hasErrors = () => {
    return !!convertFilesToTrackedSamples(selectedFiles).filter(v => v.error).length
  }

  const templateNotSelected = () => {
    const selectedPipeline = pipelines?.find(v => v.id === selectedPipelineId)
    const templateNotSelected = selectedPipeline?.requiresTemplate === true && (!selectedTemplateName || selectedTemplateName === "")
    return templateNotSelected
  }

  const onclick_setSelectedPipelineId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPipelineId(e.target.value)
    setSelectedTemplateName("");
  }

  const getTemplates = () => {
    const organismName = organism?.name ?? organization!.defaultOrganism
    const filteredLabTemplates = userLab?.pipelineTemplates?.filter(v => v.pipelineId === selectedPipelineId && v.organism === organismName && v.organization === organization?.organizationName) ?? []
    const defaultTemplates = pipelines?.find(v => v.id === selectedPipelineId)?.defaultPipelineTemplates ?? []
    return filteredLabTemplates.concat(defaultTemplates)
  }

  return <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
    {!selectedFiles.length ? <div>No Files selected.</div> : showMinimalView()}  
    <div style={{ display: selectedFiles.length ? "flex" : "none", flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
      {linkingComplete
        ? <div style={{ display: "flex", gap: 5 }}>
          <Card style={{ display: "flex", gap: 5, padding: 5, alignItems: "center" }}>
            <Icon icon="tick-circle" intent="success"></Icon>
            <strong>File Linking Complete</strong>
          </Card>
          {pipelineRun && <Card style={{ display: "flex", gap: 5, padding: 5, alignItems: "center" }}>
            <Icon icon="tick-circle" intent="success"></Icon>
            <strong>Pipeline Run Started Succesfully</strong>
            <Button icon="flows" onClick={() => {              
              loadPipelineRunDialog(pipelineRun.id)
            }}>View Pipeline Run</Button>
          </Card>}
        </div>
        :
        <>  
          
          <Switch style={{ paddingTop: 5 }} 
            disabled={linkingFiles || hasErrors() || (startPipeline && !requiredTemplateSelected)} 
            checked={startPipeline && requiredTemplateSelected} 
            onClick={(e) => {    
              setStartPipeline(!startPipeline) 
              }} 
            onChange={(e) => {
              // Update startThisPipeline when the switch is toggled
              const isChecked = (e.target as HTMLInputElement).checked;
              if (isChecked && !templateNotSelected())
                setStartPipeline(true)
              else setStartPipeline(isChecked)
            }} >Start Pipeline</Switch>
          {startPipeline && <div>
            <HTMLSelect
              fill
              value={selectedPipelineId}
              onChange={onclick_setSelectedPipelineId}
              disabled={linkingFiles}
            >
              {pipelines && pipelines.filter(v => v.canStartOnLinkFile && v.fileType === selectedFiles[0]?.fileType).map(pipeline => {
                return <option value={pipeline.id} key={pipeline.id}>{pipeline.pipelineName}</option>
              })}
            </HTMLSelect>
          </div>}
          {startPipeline && getTemplates().length > 0 && <div>
            <HTMLSelect
              fill
              value={selectedTemplateName}
              onChange={(e) => {
                setSelectedTemplateName(e.target.value)
                if (e.target.value === "")
                  setRequiredTemplateSelected(false)
                else setRequiredTemplateSelected(true)
                
              }}
              disabled={linkingFiles}
            >
              <option value="">Select Pipeline Templates</option>
              {getTemplates().map((template) => {
                return <option value={template.name} key={template.name}>{template.name}</option>
              })}
            </HTMLSelect>
          </div>}
          <Button intent="primary" icon="link" loading={linkingFiles} onClick={linkFilesButton_onClick} disabled={hasErrors()}>Link Files</Button>
        </>
      }
    </div>
  </div>
}