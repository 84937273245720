import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons';
import { Checkbox } from '@blueprintjs/core/lib/esm/components/forms/controls';
import { ControlGroup } from '@blueprintjs/core/lib/esm/components/forms/controlGroup';
import update from 'immutability-helper'
import { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ViewColumn } from '../../types/ViewTypes'
import { Card } from './ViewFieldSortCard'
import { useOrganismStore } from '../../stores/OrganismStore';
import { useAppStore } from '../../stores/AppStore';
import { useOrganizationStore } from '../../stores/OrganizationStore';
import { useOrganismFunctions } from '../../hooks/useOrganismFunctions';


const style = {
  width: 400,
}

export interface Item {
  id: string
  text: string
}

export interface ContainerState {
  cards: Item[]
}

export interface SortContainerProps {
  value: ViewColumn[]
  onChange: (...event: any[]) => void
  nationalDatabase: boolean
  errorMessage?: string
  hideSort?: boolean
  showGroup?: boolean
  showKey?: boolean
}

export function SortContainer({ value, onChange, nationalDatabase, errorMessage, hideSort, showGroup, showKey }: SortContainerProps) {
  const [requiredAdded, setRequiredAdded] = useState(false);
  const [requiredAddedFields, setRequiredAddedFields] = useState(value);
  const { organization } = useOrganizationStore();
  const { isOutbreakView } = useAppStore()
  const { getViewableFields } = useOrganismFunctions()

  const getfieldList = () => Object
    .entries(getViewableFields(nationalDatabase))
    .filter(([k, v]) => {
      if (organization?.organizationName.toLowerCase().includes('calicinet')) {
        // Return LabOBNumber for Specimen view
        return isOutbreakView ? (v.level === "Outbreak" || !v.level) : (v.level === "Specimen" || !v.level) 
      }
      else
        return true
    })
    .map(([k, v], i) => ({
      path: v.path,
      name: k,
      title: v.title ? v.title : k,
      required: v.requiredInViews
    }) as ViewColumn)

  useEffect(() => {
    onChange(value.filter(v => getViewableFields(nationalDatabase)[v.name]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalDatabase])

  useEffect(() => {
    //if (Array.isArray(value))
    //onChange(cards)    
  }, [value])

  //auto-add the required fields to the 'selected columns' section
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!requiredAdded) {
        const requiredFields = getfieldList().filter(v => v.required);
        if (requiredFields.length > 0) {
          let newFields = [...requiredAddedFields];
          requiredFields.forEach(field => {
            // Check if the required field is already in the list of selected fields
            if (!requiredAddedFields.map(v => v.name).includes(field.name)) {
              newFields.push(field);
            } else {
              // if required field is already in requiredAddedFields, add 'required' property
              newFields = newFields.map(existingField => {
                if (existingField.name === field.name) {
                  return {
                    ...existingField,
                    required: field.required,
                  }
                }
                return existingField;
              });
            }
          });
          setRequiredAddedFields(newFields);
          onChange(newFields);
          setRequiredAdded(true);
        }
      }
    }, 0);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    // If the dragged card is the key card or the target index is before the key card, do nothing
    const keyCardIndex = value.findIndex(card => card.name === 'Key');
    if (dragIndex === keyCardIndex || hoverIndex <= keyCardIndex) return;

    const newValue = update(value, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, value[dragIndex] as ViewColumn],
      ],
    })
    onChange(newValue)
    setRequiredAddedFields(newValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const renderCard = useCallback(
    (card: ViewColumn, index: number) => {
      return (
        <Card
          key={card.path}
          index={index}
          id={card.path}
          text={card.title}
          columnName={card.name}
          moveCard={moveCard}
          checkbox_OnChange={checkbox_OnChange}
          cardIsFirst={(index: number) => index === 0}
          cardIsLast={(index: number) => index === value.length - 1}
          required={card.required}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  const checkbox_OnChange = (column: ViewColumn) => {
    // Check if the column is not required before allowing to unselect it.
    if (!(showKey === true) && column.required) {
      return;
    }

    if (!value.length) column.sorted = "asc"
    if (value.map(v => v.name).includes(column.name)) {
      let newValue = value.filter(v => v.name !== column.name)
      if (value.filter(v => v.name === column.name)[0].sorted && newValue[0])
        newValue[0].sorted = "asc"
      onChange(newValue)
    }
    else{
      if(showKey === true && column.name === "Key"){
        const [keyColumn] = getfieldList().filter((v) => v.name === column.name);    
        value.unshift(keyColumn)
        onChange(value.concat([]))
      }
      else
        onChange(value.concat(column))
    }
  }

  const sortSelect_OnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(value.map(v => {
      v.sorted = v.name === e.target.value ? "asc" : undefined
      return v
    }))
  }

  const sortButton_Onclick = (dir: "asc" | "desc" | undefined) => {
    onChange(value.map(v => {
      if (v.sorted)
        v.sorted = dir
      return v
    }))
  }

  // const 

  const sortedItem = value.filter(v => v.sorted)[0]

  //add select all button
  const selectAllColumns = () => {
    const allColumns = getfieldList().filter(
      (v) => !value.map((v) => v.name).includes(v.name),
    );
    if(showKey){
      // Add 'key' field to the 0th index
      const index = allColumns.findIndex(v => v.name === 'Key');
      if (index > -1) {
        const [keyColumn] = allColumns.splice(index, 1);
        value.unshift(keyColumn);
      }
      onChange(value.concat(allColumns));
    } else 
      onChange(value.concat(allColumns));
  };

  //add deselect all button
  // Deselect all columns except those that are required
  const deselectAllColumns = () => {
    const requiredFields = value.filter(v => v.required);
    onChange(requiredFields);
  };

  const allColumnsSelected = getfieldList().length === value.length;

  return (<div className='backgroundStyle'>
    {value.length && !hideSort ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <label>
        <h6 className="bp4-heading">Sort by</h6>
        <ControlGroup>
          <div className="bp4-html-select">
            <select onChange={sortSelect_OnChange} value={sortedItem?.name}>
              {value.filter(v => !nationalDatabase || organization?.ndbIndexedFields?.includes(v.name)).map(v => <option key={v.path} value={v.name}>{v.title}</option>)}
            </select>
            <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
          </div>
          {sortedItem?.sorted === "asc" ? <Button rightIcon="sort-asc" onClick={() => sortButton_Onclick("desc")}>Ascending</Button> : null}
          {sortedItem?.sorted === "desc" ? <Button rightIcon="sort-desc" onClick={() => sortButton_Onclick("asc")}>Descending</Button> : null}
        </ControlGroup>
      </label>
    </div> : null}
    <h6 className="bp4-heading">Selected Columns</h6>
    {/* Add the "Deselect All" button */}
    {value.length > 0 && <Button onClick={deselectAllColumns} style={{ padding: '2px 5px', marginBottom: '10px', border: '1px solid #767676' }}>Deselect All</Button>}
    <DndProvider backend={HTML5Backend}>
      <div style={style}>{value.map((card, i) => renderCard(card, i))}</div>
      {/* <div style={style}>{requiredAddedFields.map((card, i) => renderCard(card, i))}</div> */}
    </DndProvider>
    {value.length === 0
      ? errorMessage
        ? <div className='errorText'>{errorMessage}</div>
        : <em>No Selected Columns</em>
      : null
    }
    <h6 className="bp4-heading">Available Columns</h6>
    {/* Add the "Select All" button */}
    {!allColumnsSelected && <Button onClick={selectAllColumns} style={{ padding: '2px 5px', marginBottom: '10px', border: '1px solid #767676' }}>Select All</Button>}
    <div>
      {/* {fieldList
        .filter(v => !value.map(v => v.name).includes(v.name))
        .map(v => <Checkbox onChange={() => checkbox_OnChange(v)} key={v.path} label={v.title} />)} */}
      {getfieldList()
        .filter(v => !value.map(v => v.name).includes(v.name) && (showKey ? true : !v.required))
        .map(v => <Checkbox checked={value.map(v => v.name).includes(v.name)} onChange={() => checkbox_OnChange(v)} key={v.path} label={v.title} />)}
    </div>
  </div>
  )
}