import * as yup from "yup";
import { FieldProps, Fields, Organism } from "../types/OrganismTypes";
import jsonLogic from 'json-logic-js';

export function GetOutbreaksYupSchema(values: any, organism: Organism | undefined, ndb?: boolean, nors?: boolean) {
  const organization = organism?.organization!
  const fieldsEntries = Object.entries(organism?.properties || {})
  const outbreaksRequiredFields = fieldsEntries.filter(([k, v]) => v.requiredInOutbreaks).map(entry => entry[0])
  const organismFields: Fields = { 
    required: ndb? ["LabOBNumber"] : (nors ? [] : outbreaksRequiredFields), 
    properties: Object.fromEntries(Object.entries(organism?.properties!).filter(([k,v]) => (!v.level || v.level === "Outbreak"))) || {}
  }


  const objSchemaEntries = fieldsEntries.map(([k, v]) => {
    let yupFieldSchema: any
    switch (v.type) {
      case "number": yupFieldSchema = yup.number(); break;
      case "numbernull": yupFieldSchema = yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null); break;
      case "date": yupFieldSchema = yup.date().nullable(true).transform((_, v) => (v === "" || v === null) ? null : new Date(v)); break;
      default: yupFieldSchema = yup.string(); break;
    }

    if (v.type === "enumnumber") {
      yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum-or-integer", `${k} value must be either 'valid enum value' or 'Number rounded to nearest whole number'.`, (value) => {
        if (value === undefined) {
          return !organismFields.required.includes(k)
        }

        // Check if the value is one of the enum values
        const isEnumValid = typeof value === "string" && v.enum!.includes(value)

        // Check if the value is an integer(Number rounded to nearest whole number)
        const isInteger = typeof value === "string" && !isNaN(value as any) && Number.isInteger(Number(value))

        return isEnumValid || isInteger
      })
    }

    if (organismFields.required.includes(k)) {
      // The field is required. If it's "LabOBNumber", it can be null.
      if (k === "LabOBNumber") {
        yupFieldSchema = yupFieldSchema.nullable(true)
      } else {
        yupFieldSchema = yupFieldSchema.required(`${k} is required.`)
      }
    }

    yupFieldSchema = CommonValidations(yupFieldSchema, values, k, v, organization, organismFields)

    return [k, yupFieldSchema]
  })
  const objSchema = Object.fromEntries(objSchemaEntries)

  return yup.object().shape(objSchema).required();

}

function CommonValidations(yupFieldSchema: any, values: any, k: string, v: FieldProps, organization: string, organismFields: Fields) {
  if (v.max) {
    const [max, message] = typeof (v.max) === "string" && v.max.startsWith("$")
      ? (v.max === "$today" && v.type === "date")
        ? [new Date(), `Future dates are not valid.`]
        : [yup.ref(v.max.substring(1)), `${k} cannot be after ${v.max.substring(1)}`]
      : [v.max, `The maximum value allowed for ${k} is ${v.max}.`]
    yupFieldSchema = yupFieldSchema.max(max, message).nullable(true)
  }

  if (v.min) {
    const [min, message] = typeof (v.min) === "string" && v.min.startsWith("$")
      ? (v.min === "$today" && v.type === "date")
        ? [new Date(), `Past dates are not valid.`]
        : [yup.ref(v.min.substring(1)), `${k} cannot be before ${v.min.substring(1)}`]
      : [v.min, `The minimum value allowed for ${k} is ${v.min}.`]
    yupFieldSchema = yupFieldSchema.min(min, message).nullable(true)
  }

  if (v.pattern)
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("pattern", `${k} must be in ${v.examples ? v.examples[0] : ""} format`, (value) => {
      return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true
    })

    if (v.invalidIf){
      //data format standardization
      if(v.invalidIf.message?.startsWith('standardization')){
        const valuesWithEnum = {
          ...values,
          enum: v.enum
        }
        var before = valuesWithEnum[k]
        var matchedEnum;
        
        if(v.invalidIf.message.split("-")?.[1] === "standardizeEnums" && before){
          matchedEnum = v.enum!.find(e => {
            const parts = e.toLowerCase().split('-');
            return parts.includes(before.toLowerCase()) || e.toLowerCase() === before.toLowerCase();
          });
        }
        var after = matchedEnum !== undefined ? matchedEnum : ""
        var beforeAfterMessage;
        if(after !== ""){
           beforeAfterMessage = `standardization before:${before} -> after:${after}`
           yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("standardization-error", beforeAfterMessage, () => !jsonLogic.apply(v.invalidIf!.rule, valuesWithEnum))
        } else {
          if (v.enum && !v.freeEntry)
          yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
            return value ? v.enum!.includes(value) : !organismFields.required.includes(k)
          })
        }
      } else 
        yupFieldSchema = (yupFieldSchema as yup.NumberSchema).test("invalid-if", v.invalidIf.message, () => !jsonLogic.apply(v.invalidIf!.rule, values))
    } 

  return yupFieldSchema
}