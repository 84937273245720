import { Controller, FieldValues, useFieldArray, useForm } from "react-hook-form"
import { Button, Callout, Card, H4, HTMLSelect, Icon, MenuItem, Text } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useOrganismStore } from "../stores/OrganismStore"
import { usePipelineTemplatesDialogStore } from "../stores/PipelineTemplatesDialogStore"
import { PipelineTemplate } from "../types/LabTypes"
import { Toaster } from "../utils/Toaster"
import { Select2 } from "@blueprintjs/select/lib/esm/components/select/select2"
import { MultiSelect2 } from "@blueprintjs/select/lib/esm/components/multi-select/multiSelect2"
import { Popover2 } from "@blueprintjs/popover2"
import { usePipelinesStore } from "../stores/PipelinesStore"
import { useOrganizationStore } from "../stores/OrganizationStore"

interface PipelineTemplateFormProps {
  activeTemplate?: PipelineTemplate
  handleTemplateSubmit: (data: FieldValues) => Promise<void>
}

export default function PipelineTemplateForm(props: PipelineTemplateFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { control, handleSubmit, reset, watch } = useForm<PipelineTemplate>()
  const { fields, append, remove } = useFieldArray({ control, name: "fields" })

  const { organism } = useOrganismStore()
  const { organization } = useOrganizationStore()
  const { pipelines } = usePipelinesStore()
  const { setIsPipelineTemplateDialogOpen } = usePipelineTemplatesDialogStore()

  useEffect(() => {
    reset(organism?.pipelineTemplates![0] ?? {
      name: "",
      pipelineId: "",
      organism: organism?.name ?? "",
      organization: organization?.organizationName ?? "",
      fields: []
    })
    if (props.activeTemplate) {
      const tempFields = []
      if (props.activeTemplate.fields && props.activeTemplate.fields.length > 0) {
        for (const field of props.activeTemplate.fields) {
          append({ fieldName: field.fieldName, fieldTitle: field.fieldTitle, fieldType: field.fieldType, fieldValue: field.fieldValue, editable: field.editable, dateFormat: field.dateFormat, enumValues: field.enumValues, condition: field.condition })
          tempFields.push({ fieldName: field.fieldName, fieldTitle: field.fieldTitle, fieldType: field.fieldType, fieldValue: field.fieldValue, editable: field.editable, dateFormat: field.dateFormat, enumValues: field.enumValues, condition: field.condition })
        }
      }
      reset({
        name: props.activeTemplate.name,
        pipelineId: props.activeTemplate.pipelineId,
        organism: organism?.name ?? "",
        organization: organization?.organizationName ?? "",
        fields: tempFields
      })
    }
  }, [props.activeTemplate, reset, append])

  const handleSubmitForm = async (data: FieldValues) => {
    try {
      setIsSubmitting(true)
      await props.handleTemplateSubmit(data)
      setIsSubmitting(false)
    } catch (e: any) {
      setIsSubmitting(false)
      Toaster.show({ message: e.message, intent: "danger", icon: "error" })

    }
  }

  watch("fields")
  return (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", maxHeight: "calc(90vh - 80px)" }}>
      <div style={{ marginTop: "24px", fontSize: "24px", paddingLeft: "24px", paddingRight: "24px" }}>{props.activeTemplate ? "Modify Template" : "Add New Template"}</div>
      <form
        style={{ width: "100%", flexGrow: 1, overflowY: "hidden" }}
        onSubmit={handleSubmit(handleSubmitForm)}

      >
        <div style={{ height: "90%", maxHeight: "90%", overflowY: "auto", position: "relative"}}>
          <div style={{display: "flex", justifyContent: "stretch" }}>
          <Controller
            name="name"
            defaultValue={""}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div style={{ marginTop: "32px", paddingLeft: "24px", paddingRight: "24px", flex: "1", display: "flex", flexDirection: "column", gap: 10  }}>
                  <label style={{ display: "block", fontSize: "20px" }}>Template Name</label>
                  <input {...field} style={{ width: "100%", paddingTop: "8px", paddingBottom: "8px", outline: "none", paddingLeft: "4px" }} />
                </div>
              )
            }}
          />
          <Controller
            name="pipelineId"
            defaultValue={""}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div style={{ marginTop: "32px", paddingLeft: "24px", paddingRight: "24px", flex: "1", display: "flex", flexDirection: "column", gap: 10 }}>
                  <label style={{ display: "block", fontSize: "20px" }}>Pipeline</label>
                  <HTMLSelect fill {...field} value={field.value || ""} style={{ width: "100%"}}>
                    <option value=""></option>
                    {pipelines?.filter(v => v.requiresTemplate).map(v => <option value={v.id}>{v.pipelineName}</option>)}                    
                  </HTMLSelect>
                </div>
              );
            }}
          />
          </div>
          <Callout intent="primary" style={{margin: 25, width: "90%"}}>All fields in template must have a specified value even if the value is "missing" otherwise the template will not save.</Callout>
          {/* <div style={{ marginTop: "32px", fontSize: "20px", paddingLeft: "24px", paddingRight: "24px" }}>Fields</div>           */}
          <H4 style={{paddingLeft: "24px"}}>Fields</H4>
          <div style={{ display: "grid", gridTemplateColumns: "4fr 4fr 4fr 60px", paddingLeft: "24px", paddingRight: "24px", marginTop: "24px" }}>
            <div >Field Name</div>
            <div>Field Type</div>
            <div >Field Value</div>
            <div></div>
          </div>
          {fields.map((item, index) => {
            const fieldName = watch(`fields.${index}.fieldName`)
            const fieldType = watch(`fields.${index}.fieldType`)
            const fieldValue = watch(`fields.${index}.fieldValue`)
            return (
              <div key={index} style={{ display: "grid", gridTemplateColumns: "4fr 4fr 4fr 60px", paddingLeft: "24px", paddingRight: "24px", marginTop: "24px" }}>
                {item.fieldTitle
                  ? <div style={{ display: "flex", gap: 5, width: "90%", marginTop: "24px" }}>
                    <Text>{item.fieldTitle}</Text>
                    {item.condition && <Popover2
                      interactionKind="hover"
                      content={<Text style={{ padding: 15 }}>
                        If {item.condition.field} {item.condition.op === "eq" ? "equals" : "doest not equal"} {item.condition.value}.
                      </Text>}>
                      <Icon icon="help" aria-label={`${item.condition.field} ${item.condition.op === "eq" ? "equals" : "doest not equal"} ${item.condition.value}`} intent="primary"></Icon>
                    </Popover2>}
                  </div>
                  : <Controller
                    name={`fields.${index}.fieldName`}
                    control={control}
                    defaultValue={item.fieldName}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <div style={{ marginTop: "24px" }}>
                          <input {...field} value={fieldName} style={{ paddingTop: "4px", paddingBottom: "4px", width: "90%" }} />
                        </div>
                      )
                    }}
                  />}
                <Controller
                  name={`fields.${index}.fieldType`}
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <div style={{ width: "90%", marginTop: "24px" }}>
                        <HTMLSelect fill {...field} value={fieldType} disabled={!item.editable}>
                          <option value="mapping">Mapping</option>
                          <option value="value">Value</option>
                        </HTMLSelect>
                      </div>
                    );
                  }}
                />
                {fieldType === "value" ?
                  (
                    <Controller
                      name={`fields.${index}.fieldValue`}
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => {
                        field.value = item.fieldValue
                        return (
                          <div style={{ marginTop: "24px", width: "90%" }}>
                            {item.enumValues
                              ? <HTMLSelect value={fieldValue} onChange={field.onChange} elementRef={field.ref} fill disabled={!item.editable}>
                                <option value={""}></option>
                                {item.enumValues.map(property => {
                                  return <option key={property} value={property}>{property}</option>
                                })}
                              </HTMLSelect>
                              : <input {...field} value={fieldValue} style={{ paddingTop: "4px", paddingBottom: "4px", width: "100%" }} disabled={!item.editable} />}
                          </div>
                        )
                      }}
                    />
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <Controller
                        name={`fields.${index}.fieldValue`}
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => {
                          field.value = item.fieldValue
                          return (
                            <div style={{ width: "90%", marginTop: "24px" }}>
                              {/* <HTMLSelect value={fieldValue} onChange={field.onChange} elementRef={field.ref} fill disabled={!item.editable}>
                                <option value={""}></option>
                                {Object.keys(organism?.properties || {}).map(property => {
                                  return <option key={property} value={property}>{property}</option>
                                })}
                              </HTMLSelect> */}
                              <MultiSelect2<string>
                                disabled={!item.editable}
                                items={Object.keys(organism?.properties || {})}
                                itemRenderer={(item, { handleClick, handleFocus, modifiers }) =>
                                  <MenuItem
                                    key={item}
                                    onClick={handleClick}
                                    onFocus={handleFocus}
                                    roleStructure="listoption"
                                    shouldDismissPopover={true}
                                    text={item}
                                    active={modifiers.active}
                                    disabled={modifiers.disabled}
                                  />
                                }
                                onItemSelect={(selectedValue) => {
                                  let fieldValueArr = fieldValue.split(";")
                                  fieldValueArr.push(selectedValue)
                                  field.onChange(fieldValueArr.join(";"));
                                }}
                                tagInputProps={{
                                  onRemove: (tag, tagIndex) => {
                                    let fieldValueArr = fieldValue.split(";")
                                    fieldValueArr.splice(tagIndex, 1)
                                    field.onChange(fieldValueArr.join(";"))
                                  }
                                }}
                                selectedItems={fieldValue.split(";")}
                                tagRenderer={(item) => item}
                                ref={field.ref}
                              >

                                <Button text={fieldValue} rightIcon="double-caret-vertical" placeholder="Select a film" />
                              </MultiSelect2>
                            </div>
                          )
                        }}
                      />
                      {organism?.properties[fieldValue]?.type === "date" && <Controller
                        name={`fields.${index}.dateFormat`}
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => {
                          field.value = item.dateFormat
                          return (
                            <div style={{ width: "90%", marginTop: "24px" }}>
                              <HTMLSelect value={item.dateFormat} onChange={field.onChange} elementRef={field.ref} fill disabled={!item.editable}>
                                <option value="">Select Date Format...</option>
                                <option value={"YYYY"}>YYYY</option>
                                <option value={"YYYY-mm"}>YYYY-mm</option>                                
                              </HTMLSelect>
                            </div>
                          )
                        }}
                      />}
                    </div>
                  )
                }
                {!item.fieldTitle && <Button style={{ marginTop: "24px" }} small onClick={() => { remove(index) }} icon="cross" minimal></Button>}
              </div>
            );
          })}
          <Button type="button" style={{ margin: "24px" }} onClick={() => append({ fieldName: "", fieldType: "mapping", fieldValue: "", editable: true }, {})}>Add Field</Button>

        </div>
        <div style={{ height: "10%" }}>
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "24px" }}>
            <Button style={{ marginRight: "16px" }} type="submit" intent="primary" disabled={isSubmitting} loading={isSubmitting}>{props.activeTemplate ? "Update" : "Create"}</Button>
            <Button
              onClick={() => {
                setIsPipelineTemplateDialogOpen(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form >
    </div >
  );
}