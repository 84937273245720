import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { SAMSOauthProvider } from './components/SAMSOauthProvider';
import { ErrorBoundary } from "react-error-boundary";
import { NonIdealState } from "@blueprintjs/core/lib/esm/components/non-ideal-state/nonIdealState";
import { Button, Card, HotkeysProvider } from '@blueprintjs/core';
import { useEffect } from 'react';
// import * as ReactDOMClient from 'react-dom/client';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
function Fallback({ error }: {error: Error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error])

  return (
    <div role="alert" style={{width: "100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginTop:100}}>
      <Card>
        <NonIdealState icon="cross-circle" title={"Uh oh.. Something went wrong :("} description={"Please contact an administrator and report the following:"}></NonIdealState>                
        <pre style={{ color: "red" }}>{error.stack}</pre>
        {/* <button onC onClick="sendMail(); return false">Send</button>        */}
        <Button onClick={() => sendMail(error.stack ?? "")}>Report Error</Button> 
      </Card>
    </div>
  );
}

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Fallback}>
    <SAMSOauthProvider>
      <HotkeysProvider>
        <App />
      </HotkeysProvider>
    </SAMSOauthProvider>
  </ErrorBoundary>
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function sendMail(errorMessage: string) {
    var link = "mailto:pulsenet@cdc.gov"             
             + "?subject=" + encodeURIComponent("PulseNet2.0 Client Error Report")
             + "&body=" + encodeURIComponent("An error was found when the following occurred: \n\n <please provide steps to reproduce> \n\n Below is the error report:\n\n" + errorMessage)
    ;
    
    window.location.href = link;
}